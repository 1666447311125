/* eslint-disable no-unused-vars */
import React, { createContext, useEffect, useState } from "react"
import { useMutation, useQuery, createRequest } from "urql"

import { ACCOUNT_QUERY } from "../queries/AccountsMutations"
import {
  CREATE_AMENITIES_MUTATION,
  DELETE_AMENITIES_MUTATION,
  CREATE_FLOORPLAN_AMENITIES_MUTATION,
  CREATE_PROPERTY_AMENITIES_MUTATION,
  CREATE_UNIT_AMENITIES_MUTATION,
  DELETE_FLOORPLAN_AMENITIES_MUTATION,
  DELETE_PROPERTY_AMENITIES_MUTATION,
  DELETE_UNIT_AMENITIES_MUTATION,
  PROPERTY_AMENITIES_QUERY,
  FLOORPLAN_AMENITIES_QUERY,
  UNIT_AMENITIES_QUERY
} from "../queries/AmenitiesMutations"
import { CURRENT_COMPANY_QUERY, COMPANIES_QUERY } from "../queries/CompaniesMutations"
import { CURRENT_USER_QUERY, UPDATE_CURRENT_USER_MUTATION } from "../queries/CurrentUserMutations"
import { UPDATE_FEED_ENABLED_MUTATION } from "../queries/FeedMutations"
import { UPDATE_UPCOMING_INTEGRATION_MUTATION } from "../queries/UpcomingIntegrationMutations"
import { FLOORPLAN_QUERY } from "../queries/FloorplansMutations"
import { INFORMATION_QUERY } from "../queries/InformationsMutations"
import { MEDIUM_QUERY } from "../queries/MediaMutations"
import { OFFICE_HOUR_QUERY } from "../queries/OfficeHoursMutations"
import { PARK_QUERY } from "../queries/ParksMutations"
import { PAYMENT_QUERY } from "../queries/PaymentsMutations"
import { PET_POLICY_QUERY } from "../queries/PetPolicyMutations"
import { PROPERTIES_DIRECTORY_QUERY, PROPERTY_DIRECTORY_QUERY } from "../queries/PropertiesDirectoryMutations"
import { ILS_RATES_QUERY } from "../queries/IlsRatesMutations"
import { PROPERTIES_QUERY, PROPERTY_QUERY } from "../queries/PropertiesMutations"
import { STATES_QUERY } from "../queries/StatesMutations"
import { UNIT_QUERY } from "../queries/UnitsMutations"
import { GEOFENCING_CAMPAINGS_QUERY, GEOFENCING_GEOFENCE_AD_PERFORMANCES_QUERY, GEOFENCING_GEOFENCE_PERFORMANCES_QUERY, GEOFENCING_TV_CAMPAINGS_QUERY, GEOFENCING_PERFORMANCE_QUERY, GEOFENCING_DETAILS_QUERY } from "../queries/GeofeacingQueries"
import { INCIDENT_REPORT_INCIDENTS_QUERY, INCIDENT_REPORT_DATA_QUERY, INCIDENT_REPORT_STATISTICS_QUERY } from "../queries/IncidentsQueries"
import { USER_RESTRICTED_FEATURES_QUERY } from "../queries/UserRestrictedFeaturesQueries"

export const GlobalContext = createContext(null)

const GlobalProvider = ({ children, ...props }) => {
  const [propertyId, setPropertyId] = useState()
  const [propertyDirectoryId, setPropertyDirectoryId] = useState()
  const [photoMediumableId, setphotoMediumableId] = useState()
  const [photoMediumableType, setphotoMediumableType] = useState()
  const [floorplanId, setFloorplanId] = useState()
  const [unitId, setUnitId] = useState()
  const [petPolicyId, setPetPolicyId] = useState()
  const [informationId, setInformationId] = useState()
  const [officeHourId, setOfficeHourId] = useState()
  const [parkId, setParkId] = useState()
  const [accountId, setAccountId] = useState()
  const [paymentId, setPaymentId] = useState()

  const [, updateCurrentUser] = useMutation(UPDATE_CURRENT_USER_MUTATION)
  const [, updateFeedEnabled] = useMutation(UPDATE_FEED_ENABLED_MUTATION)

  const [, updateUpcomingIntegration] = useMutation(UPDATE_UPCOMING_INTEGRATION_MUTATION)

  const [, createAmenities] = useMutation(CREATE_AMENITIES_MUTATION)
  const [, deleteAmenities] = useMutation(DELETE_AMENITIES_MUTATION)

  const [, createPropertyAmenities] = useMutation(CREATE_PROPERTY_AMENITIES_MUTATION)
  const [, deletePropertyAmenities] = useMutation(DELETE_PROPERTY_AMENITIES_MUTATION)

  const [, createFloorplanAmenities] = useMutation(CREATE_FLOORPLAN_AMENITIES_MUTATION)
  const [, deleteFloorplanAmenities] = useMutation(DELETE_FLOORPLAN_AMENITIES_MUTATION)

  const [, createUnitAmenities] = useMutation(CREATE_UNIT_AMENITIES_MUTATION)
  const [, deleteUnitAmenities] = useMutation(DELETE_UNIT_AMENITIES_MUTATION)

  const [{ data: currentCompanyData, fetching: currentCompanyFetching }, currentCompanyReload] = useQuery({
    query: CURRENT_COMPANY_QUERY,
    requestPolicy: "network-only"
  })

  const [{ data: currentUserData, fetching: currentUserFetching }, currentUserReload] = useQuery({
    query: CURRENT_USER_QUERY,
    requestPolicy: "network-only"
  })
  const [{ data: statesData, fetching: stateFetching }, stateReload] = useQuery({
    query: STATES_QUERY,
    requestPolicy: "network-only",
    pause: true
  })
  const [{ data: propertyAmenitiesData, fetching: propertyAmenitiesFetching }, propertyAmenitiesReload] = useQuery({
    query: PROPERTY_AMENITIES_QUERY,
    requestPolicy: "network-only",
    pause: true
  })
  const [{ data: floorplanAmenitiesData, fetching: floorplanAmenitiesFetching }, floorplanAmenitiesReload] = useQuery({
    query: FLOORPLAN_AMENITIES_QUERY,
    requestPolicy: "network-only",
    pause: true
  })
  const [{ data: unitAmenitiesData, fetching: unitAmenitiesFetching }, unitAmenitiesReload] = useQuery({
    query: UNIT_AMENITIES_QUERY,
    requestPolicy: "network-only",
    pause: true
  })
  const [{ data: companiesData, fetching: companyFetching }, companyReload] = useQuery({
    query: COMPANIES_QUERY,
    requestPolicy: "network-only"
  })
  const [{ data: propertiesData, fetching }, propertyReload] = useQuery({
    query: PROPERTIES_QUERY,
    requestPolicy: "network-only"
  })

  const [{ data: userRestrictedFeaturesData }, userRestrictedFeaturesReload] = useQuery({
    query: USER_RESTRICTED_FEATURES_QUERY,
    requestPolicy: "network-only"
  })

  const [{ data: companyUsersData }, companyUsersReload] = useQuery({
    query: `
      query {
        companyUsers {
          id
          name
        }
      }
    `,
    requestPolicy: "network-only",
  });

  // Incident Report
  const [{ data: incidentReportIncidentsData }, incidentReportIncidentsReload] = useQuery({
    query: INCIDENT_REPORT_INCIDENTS_QUERY,
    requestPolicy: "network-only"
  })

  const [{ data: incidentReportData }, incidentReportDataReload] = useQuery({
    query: INCIDENT_REPORT_DATA_QUERY,
    requestPolicy: "network-only"
  })

  const [{ data: incidentReportStatisticsData }, incidentReportStatisticsDataReload] = useQuery({
    query: INCIDENT_REPORT_STATISTICS_QUERY,
    requestPolicy: "network-only"
  })

  // Geofencing
  const [{ data: geofencingDetailsData }, geofencingDetailsReload] = useQuery({
    query: GEOFENCING_DETAILS_QUERY,
    requestPolicy: "network-only"
  })

  const [{ data: geofencingCampaignsData }, geofencingCampaignsReload] = useQuery({
    query: GEOFENCING_CAMPAINGS_QUERY,
    requestPolicy: "network-only"
  })

  const [{ data: geofencingGeofenceAdPerformacesData }, geofencingGeofenceAdPerformacesDataReload] = useQuery({
    query: GEOFENCING_GEOFENCE_AD_PERFORMANCES_QUERY,
    requestPolicy: "network-only"
  })

  const [{ data: geofencingGeofencePerformacesData }, geofencingGeofencePerformacesDataReload] = useQuery({
    query: GEOFENCING_GEOFENCE_PERFORMANCES_QUERY,
    requestPolicy: "network-only"
  })

  const [{ data: geofencingTvCampaignsData }, geofencingTvCampaignsReload] = useQuery({
    query: GEOFENCING_TV_CAMPAINGS_QUERY,
    requestPolicy: "network-only"
  })

  const [{ data: geofencingPerformanceData }, geofencingPerformanceReload] = useQuery({
    query: GEOFENCING_PERFORMANCE_QUERY,
    requestPolicy: "network-only"
  })


  // ILS Rates
  const [{ data: ilsRatesData }, ilsRatesReload] = useQuery({
    query: ILS_RATES_QUERY,
    requestPolicy: "network-only"
  })

  // Propery Directory
  const [{ data: propertiesDirectoryData }, propertiesDirectoryReload] = useQuery({
    query: PROPERTIES_DIRECTORY_QUERY,
    requestPolicy: "network-only"
  })

  const [{ data: propertyDirectoryData, fetching: fetchingPropertyDirecory }, propDirectoryReload] = useQuery({
    query: PROPERTY_DIRECTORY_QUERY,
    requestPolicy: "network-only",
    variables: {
      id: propertyDirectoryId
    },
    pause: propertyDirectoryId ? false : true
  })

  const [{ data: propertyData, fetching: fetchingProperty }, propReload] = useQuery({
    query: PROPERTY_QUERY,
    requestPolicy: "network-only",
    variables: {
      id: propertyId
    },
    pause: propertyId ? false : true
  })

  const [{ data: floorplanData, fetching: fetchingFloorplan }, floorplanReload] = useQuery({
    query: FLOORPLAN_QUERY,
    requestPolicy: "network-only",
    variables: {
      id: floorplanId
    },
    pause: floorplanId ? false : true
  })

  const [{ data: unitData, fetching: fetchingUnit }, unitReload] = useQuery({
    query: UNIT_QUERY,
    requestPolicy: "network-only",
    variables: {
      id: unitId
    },
    pause: unitId ? false : true
  })

  const [{ data: photoData, fetching: fetchingPhoto }, photoReload] = useQuery({
    query: MEDIUM_QUERY,
    requestPolicy: "network-only",
    variables: {
      mediumableId: photoMediumableId,
      mediumableType: photoMediumableType
    },
    pause: photoMediumableId ? false : true
  })

  const [{ data: petPolicyData, fetching: fechingPetPolicy }, petPolicyReload] = useQuery({
    query: PET_POLICY_QUERY,
    requestPolicy: "network-only",
    variables: {
      id: petPolicyId
    },
    pause: petPolicyId ? false : true
  })

  const [{ data: informationData, fetching: fechingInformation }, informationReload] = useQuery({
    query: INFORMATION_QUERY,
    requestPolicy: "network-only",
    variables: {
      id: informationId
    },
    pause: informationId ? false : true
  })

  const [{ data: officeHourData, fetching: fechingOfficeHour }, officeHourReload] = useQuery({
    query: OFFICE_HOUR_QUERY,
    requestPolicy: "network-only",
    variables: {
      id: officeHourId
    },
    pause: officeHourId ? false : true
  })

  const [{ data: parkData, fetching: fechingPark }, parkReload] = useQuery({
    query: PARK_QUERY,
    requestPolicy: "network-only",
    variables: {
      id: parkId
    },
    pause: parkId ? false : true
  })

  const [{ data: accountData, fetching: fechingAccount }, accountReload] = useQuery({
    query: ACCOUNT_QUERY,
    requestPolicy: "network-only",
    variables: {
      id: accountId
    },
    pause: accountId ? false : true
  })

  const [{ data: paymentData, fetching: fechingPayment }, paymentReload] = useQuery({
    query: PAYMENT_QUERY,
    requestPolicy: "network-only",
    variables: {
      id: paymentId
    },
    pause: paymentId ? false : true
  })

  const reloadPhoto = (imageableId, imageableType) => {
    setphotoMediumableId(imageableId)
    setphotoMediumableType(imageableType)
    if (!fetchingPhoto) photoReload({ requestPolicy: "network-only" })
  }

  const reloadPropertyDirectory = (id) => {
    setPropertyDirectoryId(id)
    if (!fetchingPropertyDirecory) propDirectoryReload({ requestPolicy: "network-only" })
  }

  const reloadProperty = (id) => {
    setPropertyId(id)
    if (!fetchingProperty) propReload({ requestPolicy: "network-only" })
  }

  const reloadProperties = () => {
    if (!fetching) propertyReload()
  }

  const reloadFloorplan = (id) => {
    setFloorplanId(id)
    floorplanReload({ requestPolicy: "network-only" })
  }

  const reloadUnit = (id) => {
    setUnitId(id)
    unitReload({ requestPolicy: "network-only" })
  }

  const reloadPetPolicy = (id) => {
    setPetPolicyId(id)
    petPolicyReload({ requestPolicy: "network-only" })
  }

  const reloadInformation = (id) => {
    setInformationId(id)
    informationReload({ requestPolicy: "network-only" })
  }

  const reloadOfficeHour = (id) => {
    setOfficeHourId(id)
    officeHourReload({ requestPolicy: "network-only" })
  }

  const reloadPark = (id) => {
    setParkId(id)
    parkReload({ requestPolicy: "network-only" })
  }

  const reloadAccount = (id) => {
    setAccountId(id)
    accountReload({ requestPolicy: "network-only" })
  }

  const reloadPayment = (id) => {
    setPaymentId(id)
    paymentReload({ requestPolicy: "network-only" })
  }

  return (
    <GlobalContext.Provider
      value={{
        statesData,
        companiesData,
        currentUserData,
        currentCompanyData,
        currentUserReload,
        companyReload,
        updateCurrentUser,
        propertiesData,
        propertyReload,
        companyUsersData,
        companyUsersReload,
        userRestrictedFeaturesData,
        userRestrictedFeaturesReload,
        incidentReportIncidentsData,
        incidentReportIncidentsReload,
        incidentReportData,
        incidentReportDataReload,
        incidentReportStatisticsData,
        incidentReportStatisticsDataReload,
        geofencingDetailsData,
        geofencingDetailsReload,
        geofencingCampaignsData,
        geofencingCampaignsReload,
        geofencingGeofenceAdPerformacesData,
        geofencingGeofenceAdPerformacesDataReload,
        geofencingGeofencePerformacesData,
        geofencingGeofencePerformacesDataReload,
        geofencingTvCampaignsData,
        geofencingTvCampaignsReload,
        geofencingPerformanceData,
        geofencingPerformanceReload,
        propertiesDirectoryData,
        propertiesDirectoryReload,
        ilsRatesData,
        ilsRatesReload,
        propertyDirectoryData,
        reloadPropertyDirectory,
        updateFeedEnabled,
        updateUpcomingIntegration,
        propertyData,
        reloadProperty,
        reloadPhoto,
        photoData,
        floorplanData,
        reloadFloorplan,
        unitData,
        reloadUnit,
        createAmenities,
        deleteAmenities,
        createPropertyAmenities,
        deletePropertyAmenities,
        createFloorplanAmenities,
        deleteFloorplanAmenities,
        createUnitAmenities,
        deleteUnitAmenities,
        petPolicyData,
        reloadPetPolicy,
        informationData,
        reloadInformation,
        officeHourData,
        reloadOfficeHour,
        parkData,
        reloadPark,
        accountData,
        reloadAccount,
        paymentData,
        reloadPayment,
        fetchingProperty,
        fetchingFloorplan,
        fetchingUnit,
        reloadProperties,
        propertyAmenitiesData,
        propertyAmenitiesReload,
        floorplanAmenitiesData,
        floorplanAmenitiesReload,
        unitAmenitiesData,
        unitAmenitiesReload,
        stateReload
      }}>
      {children}
    </GlobalContext.Provider>
  )
}

export default GlobalProvider
