import { CSVBoxButton } from "@csvbox/react"
import React, { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { useMutation } from "urql"

import BrynsonSquareButton from "../shared/BrynsonSquareButton"

import DesktopH5 from "./DesktopH5"
import Modal from "./Modal"
import SlideInAlert from "./SlideInAlert"
import { PropertiesContext } from "../context/PropertiesContext"
import { propertyColumnsToUpload } from "../queries/PropertiesColumns"
import { GlobalContext } from "../context/GlobalContext"

const InviteBox = styled.div`
  border: 1px solid #c8d6df;
  padding: 24px;
  margin-bottom: 16px;

  i {
    margin-right: 9px;
    font-size: 18px;
  }

  .content-row {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
  }

  h4 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 32px;
    letter-spacing: -0.02em;
    color: #0b3954;
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }
`

export default function PropertyUpload({ buttonStyle, buttonSize, buttonClassName, checkImport, style }) {
  const [showAlert, setShowAlert] = useState(false)
  const [alertContent, setAlertContent] = useState("")
  const [showModal, setShowModal] = useState(false)
  const [launchImport, setLaunchImport] = useState(false)
  const [importSuccess, setImportSuccess] = useState(false)
  const { uploadProperties } = useContext(PropertiesContext)
  const { statesData, currentUserData, propertyReload, stateReload } = useContext(GlobalContext)
  const columns = propertyColumnsToUpload

  useEffect(() => {
    stateReload()
  }, [])

  const fetchProperties = (propertyInput) => {
    const propertiesValues = {}
    propertiesValues['companyId'] = parseInt(currentUserData?.currentUser?.currentCompany?.id);
    columns.forEach((item) => {
      if (propertyInput[item.accessor]) {
        if (item.accessor === "state") {
          const state = statesData.states.find((i) => i.abbreviation === propertyInput[item.accessor]?.toUpperCase())
          propertiesValues[item.accessor_value] = parseInt(state.id)
        } else {
          propertiesValues[item.accessor] = item.type === "number" ? parseFloat(propertyInput[item.accessor].replace('$', '').replace(',', '')) : propertyInput[item.accessor]
        }
      } else {
        propertiesValues[item.accessor] = null;
      }
    })
    return propertiesValues
  }

  const handlePropertyImport = (result, data) => {

    const propertiesForUpload = data.rows.map((uploadedProperty) => (fetchProperties(uploadedProperty)));
    if (result) {
      uploadProperties({ properties: propertiesForUpload }).then((res) => {
        const uploadErrors = res?.data?.uploadProperties?.erroredProperties
        if (res?.error) {
          setShowAlert(true)
          setAlertContent(`Error importing propertiesForUpload: ${res.error} Please check your data and try again.`)
          setShowModal(false)
        } else {
          setShowAlert(true)
          setImportSuccess(true)
          setShowModal(false)
          propertyReload()
          setAlertContent(
            `${data?.row_success - uploadErrors?.length} clients invited successfully
            ${uploadErrors?.length > 0 ? `\n Could not invite ${uploadErrors?.length} properties` : ""}.`
          )
        }
      })
    } else {
      console.log("Upload fail")
    }
  }

  return (
    <div style={style}>
      <CSVBoxButton
        licenseKey="3pS7eSdChfENIQsn14fseh1OxHd8ur"
        onImport={handlePropertyImport}
        render={(launch) => (
          <BrynsonSquareButton
            type={buttonStyle}
            size={buttonSize}
            label="Import Properties"
            fullWidth={false}
            onClick={launch}
            className={buttonClassName}
          />
        )}>
        Import
      </CSVBoxButton>
      <SlideInAlert visible={showAlert} setVisible={setShowAlert}>
        {alertContent} {importSuccess && <a onClick={() => (window.property.href = "/portal/clients")}>View</a>}
      </SlideInAlert>
    </div>
  )
}
