import React, { useEffect, useContext, useImperativeHandle, forwardRef, useState } from "react"
import { SearchResultList, useFetch } from "../portal/comp_analysis/CompAnalysisReportsNew"
import { propertyColumnsCurrent } from "../queries/PropertiesColumns"
import { GlobalContext } from "../context/GlobalContext"
import { PropertiesContext } from "../context/PropertiesContext"

import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import InputField from "./InputField"
import CheckBoxExtended from "./CheckboxExtended"
import SelectField from "./SelectField"
import { TextInput } from "flowbite-react"

const identificationTypeOptions = [
  "High Rise",
  "Garden Style",
  "Mid Rise",
  "Mixed Use",
  "Standard",
  "Unspecified",
  "Low Rise",
  "House for Rent",
  "Condo for Rent",
  "Walkup",
  "Coach House",
  "Townhouse",
  "Courtyard"
].map((value) => ({
  value: value,
  label: value
}))

const rentalTypeOptions = [
  "Subsidized",
  "Assisted Senior",
  "Assisted Living",
  "Market Rate",
  "Unspecified",
  "Broker",
  "Student",
  "Temporary"
].map((value) => ({
  value: value,
  label: value
}))

const PropertyForm = forwardRef(({ property = {}, afterSave }, ref) => {
  const csrfToken = window.document.querySelector('[name="csrf-token"]').getAttribute("content")
  const { statesData, currentUserData, stateReload } = useContext(GlobalContext)
  const columns = propertyColumnsCurrent
  const { createProperty, editProperty } = useContext(PropertiesContext)

  const [currentInput, setCurrentInput] = useState(property)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const [helloDataPropertyId, setHelloDataPropertyId] = useState("")
  const [search1, setSearch1] = useState(property.helloDataPropertyName)
  const [showResultList, setShowResultList] = useState(false)
  const {
    data: results1,
    error: error1,
    isLoading: isLoading1
  } = useFetch({
    url: `/hello_data/search?q=${search1}`,
    enabled: Boolean(search1?.trim()),
    options: {
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken
      }
    }
  })

  useEffect(() => {
    stateReload()
  }, [])

  const addValue = (field, value) => {
    const current = {}
    current[field] = value
    setCurrentInput({ ...currentInput, ...current })
  }

  async function onSelectSubject(result = {}) {
    setSearch1("")

    try {
      setSearch1(result.building_name)
      setHelloDataPropertyId(result.id)
      setShowResultList(false)
    } catch (e) {
      console.log(e)
    }
  }

  const save = () => {
    const payload = getPayload()
    const handleResult = (result) => {
      if (result?.error || result?.errors) {
        setError(`Error updating property: ${result?.error || result?.errors}`)
        if (afterSave) {
          afterSave(false)
        }
      } else {
        if (afterSave) {
          afterSave(true)
        }
      }
    }

    const operation = payload.id ? editProperty : createProperty
    operation(payload).then(handleResult)
  }

  useImperativeHandle(ref, () => ({
    save,
  }))

  const getPayload = () => {
    const current = { id: property.id, companyId: parseInt(currentUserData?.currentUser?.currentCompany?.id) }
    columns.forEach(field => {
      let value = currentInput[field.accessor] || null
      if (value && typeof value === 'string') {
        if (field.type === 'decimal') { value = parseFloat(value) }
        if (field.type === 'number' ) { value = parseInt(value) }
      }
      current[field.accessor] = value
    })

    current.helloDataPropertyId = helloDataPropertyId
    current.helloDataPropertyName = search1
    if (parseInt(current.state)) {
      current.stateId = parseInt(current.state)
    }

    return current
  }


  return (
    <div className="mt-4 pb-12 px-4">
      {error && <p className="text-red-600">{error}</p>}
      {columns.map((field) => (
        <div className="mb-2 w-full" key={`property-form-${field.accessor}`}>
          {field.type === "boolean" && (
            <div className="my-4 w-full">
              <CheckBoxExtended
                name={field.Header}
                checked={property[field.accessor]}
                handleClick={(value) => {
                  addValue(field.accessor, value)
                }}
              />
            </div>
          )}
          {field.accessor === "identificationType" && (
            <div className="my-4 w-full">
              <label htmlFor={`property-form-${field.accessor}`}>{field.Header}</label>
              <SelectField
                id={`property-form-${field.accessor}`}
                placeholder={field.Header}
                options={identificationTypeOptions}
                defaultValue={{ label: property.identificationType, value: property.identificationType }}
                onChange={(value) => {
                  addValue(field.accessor, value)
                }}
                update={false}
              />
            </div>
          )}
          {field.accessor === "rentalType" && (
            <div className="my-4 w-full">
              <label htmlFor={`property-form-${field.accessor}`}>{field.Header}</label>
              <SelectField
                id={`property-form-${field.accessor}`}
                placeholder={field.Header}
                options={rentalTypeOptions}
                defaultValue={{ label: property.rentalType, value: property.rentalType }}
                onChange={(value) => {
                  addValue(field.accessor, value)
                }}
                update={false}
              />
            </div>
          )}
          {field.accessor === "state" && (
            <>
              <label htmlFor={`property-form-${field.accessor}`}>{field.Header}</label>
              <SelectField
                id={`property-form-${field.accessor}`}
                placeholder={field.Header}
                defaultValue={{ label: property.state?.name, value: property.state?.id }}
                options={statesData?.states?.map((i) => ({ value: i.id, label: i.name }))}
                onChange={(value) => {
                  addValue(field.accessor, value)
                }}
              />
            </>
          )}
          {field.accessor === "helloDataPropertyId" && (
            <>
              <label htmlFor={`property-form-${field.accessor}`}>{field.Header}</label>
              <div className="relative max-w-lg">
                <TextInput
                  id={`property-form-${field.accessor}`}
                  icon={MagnifyingGlassIcon}
                  value={search1}
                  className="lg:max-w-lg"
                  placeholder="Enter an address or building name"
                  onChange={(e) => {
                    setSearch1(e.target.value)
                    setShowResultList(true)
                  }}
                />
                <SearchResultList
                  show={Boolean(search1?.trim()) && (isLoading1 || results1?.length) && showResultList}
                  error={error1}
                  results={results1}
                  isLoading={isLoading1}
                  onSelectResult={onSelectSubject}
                />
              </div>
            </>
          )}
          {field.type === "decimal" && (
            <>
              <label htmlFor={`property-form-${field.accessor}`}>{ field.Header }</label>
                <InputField
                  id={`property-form-${field.accessor}`}
                  placeholder={field.Header}
                  defaultValue={property[field.accessor]}
                  type="number"
                  onChange={(input) => {
                    addValue(field.accessor, input.target.value)
                  }}
                />
            </>
          )}
          {
            field.type !== "boolean" &&
            field.accessor !== "state" &&
            field.accessor !== "structureType" &&
            field.accessor !== "identificationType" &&
            field.accessor !== "rentalType" &&
            field.accessor !== "helloDataPropertyId" &&
            field.accessor !== "helloDataPropertyName" &&
            field.type !== "decimal" && (
              <>
                <label htmlFor={`property-form-${field.accessor}`}>{ field.Header }</label>
                <InputField
                  id={`property-form-${field.accessor}`}
                  placeholder={field.Header}
                  defaultValue={property[field.accessor]}
                  type="text"
                  onChange={(input) => {
                    addValue(field.accessor, input.target.value)
                  }}
                />
              </>
            )}
        </div>
      ))}
    </div>
  )
})

export default PropertyForm
