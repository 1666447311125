import React, { useContext, useState, useEffect } from "react"
import { useQuery } from "urql"
import { GlobalContext } from "../../context/GlobalContext"
import Selectbox from "../../shared/Selectbox"
import AGGridTable from '../../shared/AGGridTable'
import BrynsonTabs from "../../shared/BrynsonTabs"
import { ResponsivePie } from '@nivo/pie'
const apartmentscom = require('../../../../../public/ils/apartmentscomlogo.png');
const apartmentlist = require('../../../../../public/ils/apartment-list-logo.png');
const zillow = require('../../../../../public/ils/Zillow_logo.png');
const rentcom = require('../../../../../public/ils/rent-com-logo.png');

const ILS_TYPES = ['apartments_com', 'rent_com', 'zillow', 'apartment_list']
const ILS_LABELS = {
  'apartments_com': 'CoStar',
  'rent_com': 'Rent.com',
  'zillow': 'Zillow',
  'apartment_list': 'Apartment List'
}

const ILS_LOGOS = {
  'CoStar': apartmentscom,
  'Rent.com': rentcom,
  'Zillow': zillow,
  'Apartment List': apartmentlist
}

const tabs = ILS_TYPES.map(x => ({label: ILS_LABELS[x], icon: null}))

const EXTRACTED_LISTINGS_QUERY = `
  query ExtractedListings($propertyId: ID!) {
    extractedListings(propertyId: $propertyId) {
      id
      title
      listingLink
      address
      price
      beds
      baths
      featureInfo
      amenities
      virtualTour
      package
      ilsSource
      specials
      source
      sponsored
      contract
      feedName
      updatedAt
    }
  }
`;

const PROPERTY_COMPARABLES_QUERY = `
  query PropertyComparables($propertyId: Int!) {
    propertyComparables(propertyId: $propertyId) {
      name
    }
  }
`;


const LoadingSkeleton = () => (
  <div className="p-8">
    <div className="mb-6">
      <div className="h-8 w-48 bg-gray-200 rounded animate-pulse" />
    </div>

    <div className="mb-6">
      <div className="h-10 w-full max-w-md bg-gray-200 rounded animate-pulse" />
    </div>
    <div className="mt-4">
      <div className="flex space-x-4 mb-6">
        {[1, 2, 3, 4].map((i) => (
          <div key={i} className="h-10 w-32 bg-gray-200 rounded animate-pulse" />
        ))}
      </div>

      <div>
        <div className="h-[400px] bg-gray-200 rounded animate-pulse mb-8" />
        <div className="space-y-8">
          <div>
            <div className="h-6 w-48 bg-gray-200 rounded animate-pulse mb-4" />
            <div className="h-[350px] bg-gray-200 rounded animate-pulse" />
          </div>
          <div>
            <div className="h-6 w-48 bg-gray-200 rounded animate-pulse mb-4" />
            <div className="h-[400px] bg-gray-200 rounded animate-pulse" />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default function ILSIntelligence() {
  const [selectedProperty, setSelectedProperty] = useState(null)
  const { propertiesData } = useContext(GlobalContext)

  const propertiesOptions = propertiesData?.properties?.map(x => ({ value: x.id, label: x.name })) || []

  useEffect(() => {
    if (propertiesOptions.length > 0 && !selectedProperty) {
      setSelectedProperty(propertiesOptions[0].value)
    }
  }, [propertiesOptions, selectedProperty])

  const [result] = useQuery({
    query: EXTRACTED_LISTINGS_QUERY,
    variables: { propertyId: selectedProperty },
    pause: !selectedProperty
  })

  const { data, fetching, error } = result

  const [comparableResult] = useQuery({
    query: PROPERTY_COMPARABLES_QUERY,
    variables: { propertyId: parseInt(selectedProperty) },
    pause: !selectedProperty
  })
  const { data: comparableData, fetching: comparableFetching, error: comparableError } = comparableResult

  const isLoading = !propertiesData || (!selectedProperty && propertiesOptions.length === 0) || (selectedProperty && (fetching || comparableFetching));

  const filteredTabs = tabs.filter(tab =>
    data?.extractedListings?.some(listing => ILS_LABELS[listing.source] === tab.label)
  )

  const columnDefs = [
    { title: "Property Name", accessor: "title", type: "string" },
    { title: "Address", accessor: "address", type: "string" },
    { title: "Price", accessor: "price", type: "string" },
    { title: "Package", accessor: "package", type: "string", maxWidth: 110 },
    { title: "Beds", accessor: "beds", type: "string" },
    { title: "Baths", accessor: "baths", type: "string" },
    { title: "Listing Link", accessor: "listingLink", type: "string", minWidth: 500 },
    { title: "Specials", accessor: "specials", type: "string" },
    { title: "Virtual Tour", accessor: "virtualTour", type: "boolean" },
    { title: "Feed Name", accessor: "feedName", type: "string" },
    { title: "Last Updated", accessor: "updatedAt", type: "dateTime" },
  ]

  const getLatestUpdateTime = (listings) => {
    if (!listings?.length) return null;
    return listings.reduce((latest, listing) => {
      const currentDate = new Date(listing.updatedAt);
      return latest > currentDate ? latest : currentDate;
    }, new Date(0)).toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    }).replace(',', '').replace(' at ', ' at ');
  };

  const prepareChartData = (listings) => {
    const packageCounts = listings.reduce((acc, listing) => {
      acc[listing.package] = (acc[listing.package] || 0) + 1
      return acc
    }, {})

    return Object.entries(packageCounts).map(([key, value]) => ({
      id: key,
      value: value,
    }))
  }

  const renderTabContent = (ilsType) => {
    const logoDimensions = {
      'CoStar': { width: 140, height: 50 },
      'Apartment List': { width: 140, height: 50 },
      'Zillow': { width: 80, height: 80 },
      'Rent.com': { width: 80, height: 80 }
    }

    const filteredData = data?.extractedListings.filter(
      listing => ILS_LABELS[listing.source] === ilsType
    ) || []

    const lastUpdated = getLatestUpdateTime(filteredData);
    const lastUpdatedText = lastUpdated ? ` last updated at ${lastUpdated}` : '';

    // If no data for this ILS, show message
    if (filteredData.length === 0) {
      return (
        <div>
          <div className="mb-6 text-sm text-gray-600 italic">
            This information should be used as one of several factors in your decision-making process.
          </div>
          <div className="flex flex-col items-center justify-center py-12">
            <div className="mb-6" style={{ height: '80px' }}>
              <img
                src={ILS_LOGOS[ilsType]}
                alt={`${ilsType} logo`}
                style={{
                  maxHeight: '100px',
                  maxWidth: '300px',
                  width: 'auto',
                  objectFit: 'contain'
                }}
              />
            </div>
            <p className="text-gray-600 text-center mt-4">
              Please reach out to your client representative for this data.
            </p>
          </div>
          <div className="mt-8 p-4 bg-gray-50 text-sm text-gray-600 rounded">
            <h4 className="font-semibold mb-2">Data Source Disclaimer</h4>
            <p className="mb-4">
              The data presented in this section is provided by a paid partnership and is integrated into our platform for your convenience.
              While we make reasonable efforts to maintain accurate and up-to-date information, this data may not reflect the most recent
              changes or updates from our partner.
            </p>
            <p className="mb-2">Please note:</p>
            <ol className="list-decimal ml-5 mb-4">
              <li className="mb-1">We recommend verifying critical data points through additional sources</li>
              <li className="mb-1">The timeliness and accuracy of this data depends on our data partner's updates</li>
            </ol>
            <p>
              This information is provided "as is" without any warranties, express or implied. Brynson does not independently verify
              or guarantee the accuracy, completeness, or timeliness of this third-party data.
            </p>
          </div>
        </div>
      );
    }

    // Get subject property name
    const subjectPropertyName = propertiesOptions.find(p => p.value === selectedProperty)?.label;

    // Create a full set of comparable listings with empty data where needed
    const fullCompSet = comparableData?.propertyComparables.map(comp => {
      const existingListing = filteredData.find(listing => listing.title === comp.name);
      return existingListing || {
        title: comp.name,
        package: "Not found",
        address: "",
        price: "",
        beds: "",
        baths: "",
        listingLink: "",
        specials: "",
        virtualTour: "",
        feedName: "",
        updatedAt: ""
      };
    }) || [];

    // Add subject property to the start if it exists
    const subjectListing = filteredData.find(listing => listing.title === subjectPropertyName);
    const allComparables = subjectListing
      ? [subjectListing, ...fullCompSet]
      : fullCompSet;

    const getRowStyle = params => {
      if (!params.data || !params.data.title) return null;
      if (params.data.title === subjectPropertyName) {
        return { backgroundColor: '#fef3c7' }; // Tailwind's bg-bgold equivalent
      }
      return null;
    };

    const chartData = prepareChartData(filteredData)

    return (
      <div>
        <div className="mb-6 text-sm text-gray-600 italic">
          This information should be used as one of several factors in your decision-making process.
        </div>

        <div style={{ height: '400px' }}>
          <ResponsivePie
            data={chartData}
            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
            innerRadius={0.6}
            padAngle={0.7}
            cornerRadius={3}
            activeOuterRadiusOffset={8}
            colors={{ scheme: 'nivo' }}
            borderColor={{ from: 'color', modifiers: [['darker', 0.6]] }}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="#333333"
            arcLinkLabelsThickness={2}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
            layers={[
              'arcs',
              'arcLabels',
              'arcLinkLabels',
              'legends',
              ({ centerX, centerY }) => {
                const { width, height } = logoDimensions[ilsType]
                return (
                  <image
                    x={centerX - width/2}
                    y={centerY - height/2}
                    width={width}
                    height={height}
                    xlinkHref={ILS_LOGOS[ilsType]}
                    preserveAspectRatio="xMidYMid meet"
                  />
                )
              }
            ]}
          />
        </div>

        {allComparables.length > 0 && (
          <>
            <h5 className="text-sm text-bgray font-semibold mb-4 text-right font-montserrat">All data{lastUpdatedText}</h5>
            <h4 className="text-md font-semibold mb-4">Competitor Listings</h4>
            <AGGridTable
              data={allComparables}
              headerColumns={columnDefs}
              customCellRenderers={{}}
              customHeaderRenderers={{}}
              height='350px'
              getRowStyle={getRowStyle}
            />
            <div className="mb-8"></div>
          </>
        )}

        {filteredData.length > 0 && (
          <>
            <h4 className="text-md font-semibold mb-4">All Listings</h4>
            <AGGridTable
              data={filteredData}
              headerColumns={columnDefs}
              customCellRenderers={{}}
              customHeaderRenderers={{}}
            />
          </>
        )}

        <div className="mt-8 p-4 bg-gray-50 text-sm text-gray-600 rounded">
          <h4 className="font-semibold mb-2">Data Source Disclaimer</h4>
          <p className="mb-4">
            The data presented in this section is provided by a paid partnership and is integrated into our platform for your convenience.
            While we make reasonable efforts to maintain accurate and up-to-date information, this data may not reflect the most recent
            changes or updates from our partner.
          </p>
          <p className="mb-2">Please note:</p>
          <ol className="list-decimal ml-5 mb-4">
            <li className="mb-1">We recommend verifying critical data points through additional sources</li>
            <li className="mb-1">The timeliness and accuracy of this data depends on our data partner's updates</li>
          </ol>
          <p>
            This information is provided "as is" without any warranties, express or implied. Brynson does not independently verify
            or guarantee the accuracy, completeness, or timeliness of this third-party data.
          </p>
        </div>
      </div>
    )
  }

  return (
    <div className="p-8">
      {isLoading ? (
        <LoadingSkeleton />
      ) : (
        <>
          <h1 className="font-semibold text-xl mb-6 flex flex-start">ILS Intelligence <span className="text-xxs ml-1 -mt-1 text text-bgold">BETA</span></h1>
          <Selectbox
            options={propertiesOptions}
            id="brynson-property"
            value={selectedProperty}
            defaultValue={
              selectedProperty && propertiesOptions.length > 0
                ? propertiesOptions.find(x => x.value === selectedProperty)
                : null
            }
            onChange={(value) => {
              setSelectedProperty(value)
            }}
          />

          <div>
            {error && <p className="text-red-500">Error: {error.message}</p>}
            {comparableError && <p className="text-red-500">Error: {comparableError.message}</p>}
            <div className="mt-4">
              <BrynsonTabs tabs={tabs} className="">
                {tabs.map((tab, index) => (
                  <div key={index}>
                    {renderTabContent(tab.label)}
                  </div>
                ))}
              </BrynsonTabs>
            </div>
          </div>
        </>
      )}
    </div>
  )
}