import React, { useState, useEffect } from "react"
import Cleave from 'cleave.js/react';

const TimeInput = ({ defaultValue, onChange }) => {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <Cleave
      options={{
        time: true,
        timeFormat: '12',
        timePattern: ['h', 'm']
      }}
      placeholder="Open"
      className="border border-gray-300 rounded p-2 text-center w-32"
      value={value}
      onChange={input => onChange(input.target.value)}
    />
  );
};

export default TimeInput;
