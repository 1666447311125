import React, { useState, useContext, useMemo, useEffect } from "react"
import styled from "styled-components"
import BrynsonSquareButton from "../../shared/BrynsonSquareButton"
import Modal from "../../shared/Modal"
import DesktopH5 from "../../shared/DesktopH5"
import InputField from "../../shared/InputField"
import CurrencyInput from "../../shared/CurrencyInput"
import { GlobalContext } from "../../context/GlobalContext"
import { useMutation, useQuery } from 'urql';
import {  CREATE_CLIENT_ACTIVITY_MUTATITION, UPDATE_CLIENT_ACTIVITY_MUTATITION } from "../../queries/ClientActivityQueries"
import SelectField from "../../shared/SelectField"
import BrynsonDatePicker from "../../shared/BrynsonDatePicker"

const fields = [
  {
    accessor: "propertyId",
    title: "Property",
    type: "int",
  },
  {
    accessor: "propertyName",
    title: "Property Name",
    type: "string",
  },
  {
    accessor: "dateRequested",
    title: "Date Requested",
    type: "date",
  },
  {
    accessor: "userId",
    title: "Requested By",
    type: "int",
  },
  {
    accessor: "requestedBy",
    title: "Requested By (Name)",
    type: "string",
  },
  {
    accessor: "ils",
    title: "ILS",
    type: "string",
  },
  {
    accessor: "currentPackage",
    title: "Current Package",
    type: "string",
  },
  {
    accessor: "currentPrice",
    title: "Current Price",
    type: "currency",
  },
  {
    accessor: "newPackage",
    title: "New Package",
    type: "string",
  },
  {
    accessor: "newPrice",
    title: "New Price",
    type: "currency",
  },
  {
    accessor: "requestedEffectiveDate",
    title: "Requested Effective Date",
    type: "date",
  },
  {
    accessor: "requestedType",
    title: "Request Type",
    type: "string",
  },
  {
    accessor: "dateInitiatedByBrynson",
    title: "Date initiated by Brynson",
    type: "string",
  },
  {
    accessor: "ilsRepRequestSentToName",
    title: "ILS rep request sent to (Name)",
    type: "string",
  },
  {
    accessor: "confirmedReceiptByIlsDate",
    title: "Confirmed receipt by ILS - Date",
    type: "date",
  },
  {
    accessor: "ilsSignedAgreementCompletedDate",
    title: "ILS signed agreement (Completed Date)",
    type: "date",
  },
  {
    accessor: "liveDateOnOffIls",
    title: "Live Date On/Off ILS",
    type: "date",
  },
  {
    accessor: "notes",
    title: "Notes",
    type: "string",
  },
  {
    accessor: "auditComments",
    title: "Audit Comments",
    type: "string",
  },
]

export default function ActivityLogForm({ item = {}, OpenModalComponent = null}) {
  const title = item?.id ? 'Edit Active Log' : 'Add Active Log'
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [currentInput, setCurrentInput] = useState(item)
  const showPropertyName = useMemo(() => currentInput.propertyId == null)
  const showRequestedBy = useMemo(() => currentInput.userId == null)

  const [, create] = useMutation(CREATE_CLIENT_ACTIVITY_MUTATITION);
  const [, update] = useMutation(UPDATE_CLIENT_ACTIVITY_MUTATITION);

  const { propertiesData: propertiesData } = useContext(GlobalContext)
  const propertyOptions = propertiesData?.properties.map( x => ({ value: x.id, label: x.name })) || []

  const { companyUsersData: companyUsersData } = useContext(GlobalContext)
  const userOptions = companyUsersData?.companyUsers.map( x => ({ value: x.id, label: x.name })) || []

  const DefaultOpenModalComponent = ({ onClick, label }) => (
    <a className="font-medium text-bblue underline" onClick={onClick}>{label}</a>
  );
  const OpenComponent = OpenModalComponent || DefaultOpenModalComponent;

  const addValue = (field, value) => {
    const current = {}
    current[field] = value
    setCurrentInput({ ...currentInput, ...current })
  }

  var getFloatValue = (value) => {
    var numericValue = value
      .replace("$", "")
      .replace(/,/g, "")
      .trim();

    return parseFloat(numericValue) || 0;
  };

  const getPayload = () => {
    const current = { id: item.id }
    fields.forEach(field => {
      let value = currentInput[field.accessor] || null
      if (value && typeof value === 'string') {
        if (field.type === 'currency') { value = getFloatValue(value) }
        if (field.type === 'date') { value.toString() }
      }
      current[field.accessor] = value
    })

    current.propertyName = current.propertyId ? propertyOptions.find(x => x.value === current.propertyId)?.label : current.propertyName
    current.requestedBy = current.userId ? userOptions.find(x => x.value === current.userId)?.label : current.requestedBy

    return current
  }

  const save = () => {
    setLoading(true)
    const payload = getPayload()


    if(payload.id) {
      update(payload).then((result) => {
        if (result?.error || result?.data?.editProperty?.errors) {
          setError(`Error creating client activity: ${result?.error || result?.data?.editProperty?.errors}`)
        } else {
          setOpen(false)
        }
        setLoading(false)
      })
    } else {
      create(payload).then((result) => {
        if (result?.error || result?.data?.clientActivity?.errors) {
          setError(`Error updating client activity: ${result?.error || result?.data?.clientActivity?.errors}`)
        } else {
          setOpen(false)
        }
        setLoading(false)
      })
    }
  }

  return (
    <>
      <OpenComponent onClick={() => setOpen(true)} label={item?.name} />
      <Modal open={open} key='property-info-form-model' closeModal={() => setOpen(false)}
        header={
          <>
            <DesktopH5 content={title} />
          </>
        }
        footer={
          <>
            <BrynsonSquareButton
              type="tertiary"
              onClick={() => setOpen(false)}
              label="Cancel"
            />
            <BrynsonSquareButton
              type="primary"
              testId="add-new-property-button"
              loading={loading}
              onClick={save}
              label="Save"
            />
          </>
        }>
        <div className="mb-2 mr-2">
          {error && <p className="text-red-600">{error}</p>}
          { fields.map((field) => (
            <React.Fragment key={`activy-log-field-${field.accessor}`}>
              {field.accessor === "propertyId" &&
                <div className="mb-2">
                  <label htmlFor={field.accessor}>{field.title}</label>
                  <SelectField
                    isClearable={true}
                    options={propertyOptions}
                    id={field.accessor}
                    isDisabled={item.propertyId}
                    defaultValue={item.propertyId ? propertyOptions.find(x => x.value == item.propertyId) : null }
                    onChange={(value) => {
                      addValue(field.accessor, value)
                    }}
                  />
                </div>
              }
              {field.accessor === "propertyName" && showPropertyName &&
                <div className="mb-2">
                  <label htmlFor={field.accessor}>{field.title}</label>
                  <InputField
                    id={field.accessor}
                    placeholder={field.title}
                    defaultValue={item.propertyName}
                    onChange={(input) => {
                      addValue(field.accessor, input.target.value)
                    }}
                  />
                </div>
              }
              {field.accessor === "userId" &&
                <div className="mb-2">
                  <label htmlFor={field.accessor}>{field.title}</label>
                  <SelectField
                    isClearable={true}
                    options={userOptions}
                    id={field.accessor}
                    defaultValue={item.userId ? userOptions.find(x => x.value == item.userId) : null }
                    onChange={(value) => {
                      addValue(field.accessor, value)
                    }}
                  />
                </div>
              }
              {field.accessor === "requestedBy" && showRequestedBy &&
                <div className="mb-2">
                  <label htmlFor={field.accessor}>{field.title}</label>
                  <InputField
                    id={field.accessor}
                    placeholder={field.title}
                    defaultValue={item.requestedBy}
                    onChange={(input) => {
                      addValue(field.accessor, input.target.value)
                    }}
                  />
                </div>
              }
              {!['propertyId', 'propertyName', 'requestedBy'].includes(field.accessor) &&
                <div>
                  {field.type === "string" &&
                    <div className="mb-2">
                      <label htmlFor={field.accessor}>{field.title}</label>
                      <InputField
                        id={field.accessor}
                        placeholder={field.title}
                        defaultValue={item[field.accessor]}
                        onChange={(input) => {
                          addValue(field.accessor, input.target.value)
                        }}
                      />
                    </div>
                  }
                  {field.type === "currency" &&
                    <div className="mb-2">
                      <label htmlFor={field.accessor}>{field.title}</label>
                      <CurrencyInput
                        id={field.accessor}
                        placeholder={field.title}
                        defaultValue={item[field.accessor]}
                        onChange={(input) => {
                          addValue(field.accessor, input.target.value)
                        }}
                      />
                    </div>
                  }
                  {field.type === "date" &&
                    <div className="mb-2">
                      <label htmlFor={field.accessor}>{field.title}</label>
                      <BrynsonDatePicker
                        id={field.accessor}
                        selectedDate={
                          item[field.accessor]
                          ? new Date(`${item[field.accessor]}T00:00:00`)
                          : null
                        }
                        onChange={(date) => addValue(field.accessor, date)}
                      />
                    </div>
                  }
                </div>
              }

            </React.Fragment>
          ))}
        </div>
      </Modal>
    </>
  )
}