import clsx from "clsx"
import { Table } from "flowbite-react"
import React from "react"
import { CheckIcon } from '@heroicons/react/24/outline';

export default function AmenitiesTable({ amenities = [], firstAmenities = [], amenitiesTye }) {
  return (
    <Table>
      <Table.Head>
        <Table.HeadCell>{`${amenitiesTye} Amenities`}</Table.HeadCell>
        {firstAmenities.map((amenity) => (
          <Table.HeadCell key={amenity}>{amenity}</Table.HeadCell>
        ))}
      </Table.Head>
      <Table.Body className="border-t text-sm">
        {amenities.map((item, index) => (
          <Table.Row
            key={index}
            className={clsx(
              "border-b hover:bg-blue-100",
              index == 0 ? "bg-blue-50 font-semibold text-blue-800" : "font-medium text-gray-800"
            )}>
            <Table.Cell
              key={index}
              className="max-w-[10rem] overflow-hidden text-ellipsis whitespace-nowrap p-0 py-2 pl-4">
              {item.property}
            </Table.Cell>
            {firstAmenities.map((amenity) => (
              <Table.Cell
                key={amenity}
                title={item.property}
                className="max-w-[10rem] overflow-hidden text-ellipsis whitespace-nowrap p-0 py-2">
                {item[amenity] ? <CheckIcon className="mx-auto h-5 w-5 text-green-500" /> : null}
              </Table.Cell>
            ))}
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  )
}
