import {
  AdjustmentsHorizontalIcon,
  DocumentIcon,
  PencilSquareIcon,
  TrashIcon,
  DocumentCheckIcon,
  PhotoIcon
} from "@heroicons/react/24/solid"
import React, { useContext, useEffect, useMemo, useState } from "react"
import { Tooltip } from "react-tooltip"
import styled from "styled-components"

import { GlobalContext } from "../context/GlobalContext"
import { UnitsContext } from "../context/UnitsContext"

import BreadCrumbs from "./BreadCrumbs"
import BrynsonSquareButton from "./BrynsonSquareButton"
import BrynsonSquareLink from "./BrynsonSquareLink"
import BrynsonTable from "./BrynsonTable"
import CheckBoxExtended from "./CheckboxExtended"
import DesktopTitle3 from "./DesktopTitle3"
import Loading from "./Loading"
import Modal from "./Modal"
import Sidebar from "./Sidebar"
import UnitUpload from "./UnitUpload"

const TableWrapper = styled.div`
  .table th {
    white-space: nowrap;
    font-size: 13px;
  }
`

export default function UnitShow({ params }) {
  const page = "unit"
  const { propertyData, reloadProperty, createUnitAmenities, deleteUnitAmenities, unitAmenitiesData, unitAmenitiesReload } = useContext(GlobalContext)
  const { deleteUnit } = useContext(UnitsContext)
  const [showDeleteUnit, setShowDeleteUnit] = useState(false)
  const [currentLoading, setLoading] = useState(false)
  const [currentId, setCurrentId] = useState()
  const [showSidebar, setShowSidebar] = useState(false)
  const [selected, setSelected] = useState()
  const [breadCrumbs, setBreadCrumbs] = useState()
  const [amenities, setAmenities] = useState([])
  const [globalAmenities, setGlobalAmenities] = useState([])
  const [search, setSearch] = useState()
  const [uploadLoading, setUploadLoading] = useState(false)

  const handleUploadLoading = (value) => {
    setUploadLoading(value)
  }

  const showHideProperty = (property) => {}

  useEffect(() => {
    if (params) {
      const id = JSON.parse(params)["id"]
      reloadProperty(parseInt(id))
    }
  }, [params])

  const tableHeader = () => (
    <div className="column is-5 is-offset-4 table-actions">
      <div className="mr-2">
        <UnitUpload buttonStyle="white" propertyId={`${JSON.parse(params)["id"]}`} setLoading={handleUploadLoading} />
      </div>

      <BrynsonSquareLink
        type="primary"
        label="Add new unit"
        href={`/portal/units/new?id=${JSON.parse(params)["id"]}`}
      />
    </div>
  )

  const unitColumns = [
    {
      accessor: "name",
      Header: "Unit",
      type: "text"
    },
    {
      accessor: "floorplan.name",
      Header: "Floorplan",
      type: "text"
    },
    {
      accessor: "floorplan.rentMin",
      Header: "Rent",
      type: "text"
    },
    {
      id: "actions",
      accessor: "actions",
      className: "text-center",
      Cell: (props) => (
        <div className="row flex justify-end gap-4">
          <button
            data-tooltip-id="tooltip"
            data-tooltip-content="Remove"
            className="font-medium text-bblue underline"
            onClick={() => {
              setCurrentId(props.cell.row.original.id)
              setShowDeleteUnit(true)
            }}>
            <TrashIcon width={24} height={24} />
          </button>
          <button
            data-tooltip-id="tooltip"
            data-tooltip-content="Edit"
            className="font-medium text-bblue underline"
            onClick={() => {
              window.location.href = `/portal/units/${props.cell.row.original.id}/edit`
            }}>
            <PencilSquareIcon width={24} height={24} />
          </button>
          <button
            data-tooltip-id="tooltip"
            data-tooltip-content="Amenities"
            className="font-medium text-bblue underline"
            onClick={() => {
              const unit = propertyData?.property?.units.find((item) => item.id === props.cell.row.original.id)
              if (unit) {
                setSelected(unit)
                setShowSidebar(true)
              }
            }}>
            <AdjustmentsHorizontalIcon width={24} height={24} />
          </button>
          {props.cell.row.original.concession ? (
            <div>
              <a
                data-tooltip-id="tooltip"
                data-tooltip-content="Concessions"
                className="text-bblue"
                href={`/portal/concessions/${props.cell.row.original.id}/edit?source=Unit&back=units&back_id=${
                  JSON.parse(params)["id"]
                }`}>
                <DocumentIcon width={24} height={24} />
              </a>
            </div>
          ) : (
            <div>
              <a
                data-tooltip-id="tooltip"
                data-tooltip-content="Concessions"
                className="text-bblue"
                href={`/portal/concessions/new?id=${props.cell.row.original.id}&source=Unit&back=units&back_id=${
                  JSON.parse(params)["id"]
                }`}>
                <DocumentIcon width={24} height={24} />
              </a>
            </div>
          )}
          {props.cell.row.original.availability ? (
            <div>
              <a
                data-tooltip-id="tooltip"
                data-tooltip-content="Availability"
                className="text-bblue"
                href={`/portal/availabilities/${props.cell.row.original.id}/edit?back_id=${JSON.parse(params)["id"]}`}>
                <DocumentCheckIcon width={24} height={24} />
              </a>
            </div>
          ) : (
            <div>
              <a
                data-tooltip-id="tooltip"
                data-tooltip-content="Availability"
                className="text-bblue"
                href={`/portal/availabilities/new?id=${props.cell.row.original.id}&back_id=${
                  JSON.parse(params)["id"]
                }`}>
                <DocumentCheckIcon width={24} height={24} />
              </a>
            </div>
          )}
          <div>
            <a
              data-tooltip-id="tooltip"
              data-tooltip-content="Photos"
              className="text-bblue"
              href={`/portal/media?id=${props.cell.row.original.id}&source=Unit`}>
              <PhotoIcon width={24} height={24} />
            </a>
          </div>
        </div>
      )
    }
  ]

  const checked = (item) => {
    const amenity = selected?.amenitiesRecords?.find((i) => parseInt(i.amenityId) === parseInt(item.id))
    return amenity ? true : false
  }

  useMemo(() => {
    if (propertyData) {
      const breadCrumbs = [
        { link: "/portal/dashboard", title: "dashboard" },
        { link: "/portal/listings", title: `Property ${propertyData.property.name || ""}` },
        { link: null, title: page }
      ]
      setBreadCrumbs(breadCrumbs)
    }
  }, [propertyData])

  const breadCrumbHeader = () => (
    <div className="mb-6">
      <BreadCrumbs items={breadCrumbs} />
    </div>
  )

  const filterAmenities = () => {
    let amenities = globalAmenities
    if (search && amenities) {
      amenities = amenities.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))
    }
    setAmenities(amenities)
  }

  useEffect(() => {
    if (unitAmenitiesData) {
      setGlobalAmenities(unitAmenitiesData.unitAmenities)
    }
  }, [unitAmenitiesData])

  useEffect(() => {
    filterAmenities()
  }, [selected])

  useEffect(() => {
    filterAmenities()
  }, [search])

  useEffect(() => {
    unitAmenitiesReload()
  }, [])

  return (
    <div className="p-8">
      <Tooltip id="tooltip" />
      {propertyData?.property?.units && !uploadLoading ? (
        <>
          {breadCrumbs && breadCrumbHeader()}
          <TableWrapper>
            <BrynsonTable
              data={propertyData?.property?.units || []}
              columns={unitColumns}
              countTitle={propertyData?.property?.units.length > 1 ? "total units" : "unit"}
              header={tableHeader}
              rowClick={showHideProperty}
            />
          </TableWrapper>
        </>
      ) : (
        <Loading />
      )}
      <Sidebar
        visible={showSidebar}
        header={() => (
          <h5 className="grow text-center text-lg font-bold">{selected?.name ? selected?.name : ""} Amenities</h5>
        )}
        backgroundColor="#FFF"
        iconName="close"
        closeSidebar={() => {
          setShowSidebar(false)
        }}>
        {selected && showSidebar && (
          <>
            <input
              className="focus:border-teal active:border-teal m-0 mb-4 w-full rounded border border-geyser bg-white px-3 py-2 text-navy shadow-none outline-0"
              onChange={(e) => {
                setSearch(e.target.value)
              }}
            />
            <div className="row flex grid grid-cols-2 flex-wrap">
              {amenities?.map((item) => (
                <div className="m-2" key={`amenities-${item.id}`}>
                  <CheckBoxExtended
                    name={item.name}
                    checked={checked(item)}
                    handleClick={(value) => {
                      if (value) {
                        createUnitAmenities({
                          unitId: parseInt(selected?.id),
                          amenityId: parseInt(item?.id)
                        }).then((result) => {
                          const id = JSON.parse(params)["id"]
                          reloadProperty(parseInt(id))
                        })
                      } else {
                        deleteUnitAmenities({
                          unitId: parseInt(selected?.id),
                          id: parseInt(item?.id)
                        }).then((result) => {
                          const id = JSON.parse(params)["id"]
                          reloadProperty(parseInt(id))
                        })
                      }
                    }}
                  />
                </div>
              ))}
            </div>
          </>
        )}
      </Sidebar>
      <Modal
        open={showDeleteUnit}
        closeModal={() => {
          setShowDeleteUnit(false)
        }}
        size="small"
        header={
          <div>
            <DesktopTitle3 content="Delete unit" />
          </div>
        }
        footer={
          <>
            <BrynsonSquareButton
              type="link"
              size="medium"
              onClick={() => {
                setShowDeleteUnit(false)
              }}
              label="Go Back"
            />
            <BrynsonSquareButton
              type="warning"
              size="medium"
              loading={currentLoading}
              className={"flex items-center justify-center"}
              onClick={() => {
                setLoading(true)
                deleteUnit({ id: currentId }).then((result) => {
                  setLoading(false)
                  setShowDeleteUnit(false)
                  const id = JSON.parse(params)["id"]
                  reloadProperty(parseInt(id))
                })
              }}
              label="Delete unit"
            />
          </>
        }>
        <DesktopTitle3 content="Are you sure you want to delete this unit? This action can't be undone." />
      </Modal>
    </div>
  )
}
