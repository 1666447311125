import * as dayjs from "dayjs"
import { Button } from "flowbite-react"
import React, { useContext, useEffect } from "react"

import { GlobalContext } from "../../context/GlobalContext"
import BrynsonTable from "../../shared/BrynsonTable"

// export const HELLO_DATA_REPORT_LIST_ENDPOINT = "https://api.hellodata.ai/property_report/list"
export const HELLO_DATA_REPORT_LIST_ENDPOINT = "/hello_data/reports"

async function fetchPropertyReports() {
  const res = await fetch(HELLO_DATA_REPORT_LIST_ENDPOINT)
  return await res.json()
}

const LoadingSkeleton = () => (
  <div className="p-8">
    {/* Header Skeleton */}
    <div className="mb-6">
      <div className="h-8 w-48 bg-gray-200 rounded animate-pulse" />
    </div>

    {/* Property Selector Skeleton */}
    <div className="mb-6">
      <div className="h-10 w-full max-w-md bg-gray-200 rounded animate-pulse" />
    </div>

    {/* Reports Grid Skeleton */}
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {[1, 2, 3, 4, 5, 6].map((i) => (
        <div key={i} className="bg-white rounded-lg shadow-sm p-6">
          {/* Report Title */}
          <div className="h-6 bg-gray-200 w-3/4 rounded animate-pulse mb-4" />

          {/* Report Details */}
          <div className="space-y-3">
            <div className="h-4 bg-gray-200 w-1/2 rounded animate-pulse" />
            <div className="h-4 bg-gray-200 w-2/3 rounded animate-pulse" />
            <div className="h-4 bg-gray-200 w-1/3 rounded animate-pulse" />
          </div>
        </div>
      ))}
    </div>
  </div>
);


export default function CompAnalysisReports() {
  const [reports, setReports] = React.useState({ data: [], loading: true })
  const { currentUserData } = useContext(GlobalContext)
  const email = currentUserData?.currentUser?.email
  const isCompanyUser = email?.indexOf("@brynson.com") > -1 ? true : false
  async function onFetchReports() {
    const reports = await fetchPropertyReports()
    setReports({ data: reports, loading: false })
  }

  async function onDeleteReport(id) {
    if (confirm("Are you sure you want to delete this report?")) {
      const token = document.querySelector('meta[name="csrf-token"]').content
      const res = await fetch(`/hello_data/reports/${id}`, { method: "DELETE", headers: { "X-CSRF-Token": token } })

      if (res.ok) {
        onFetchReports()
      }
    }
  }

  async function onEditReport(id) {
    window.location.href = `/portal/comp_analysis_reports_edit/${id}`
  }

  useEffect(() => {
    onFetchReports()
  }, [])

  const columns = [
    {
      accessor: "name",
      Header: "Report Name",
      Cell: (props) => {
        return (
          <a
            className="cursor-pointer hover:text-blue-500"
            href={`/portal/comp_analysis_reports/${props.row.original.id}`}>
            <div className="font-semibold">{props.row.original.name}</div>
            <div>{props.row.original.street_address}</div>
            {props.row.original.street_address ? (
              <div>
                {props.row.original.city}, {props.row.original.state} {props.row.original.zip_code}
              </div>
            ) : null}
          </a>
        )
      }
    },
    {
      accessor: "year_built",
      Header: "Year Built",
      className: "align-middle",
      type: "number"
    },
    {
      accessor: "number_units",
      Header: "Units",
      className: "align-middle",
      type: "number"
    },
    {
      accessor: "comparables_count",
      Header: "Comps",
      className: "align-middle",
      type: "number"
    },
    {
      accessor: "updated_at",
      Header: "Last Modified",
      className: "align-middle",
      Cell: ({ value }) => <div>{dayjs(value).format("MM/DD/YYYY HH:mm:ss")}</div>
    },
    {
      accessor: "id",
      Header: "Actions",
      className: "align-middle",
      Cell: (props) => (
        <div className="flex items-center space-x-2">
          <Button
            size="xs"
            className="mr-2"
            onClick={() => {
              onEditReport(props.row.original.id)
            }}>
            Edit
          </Button>
          {isCompanyUser && (
            <Button
              size="xs"
              color="failure"
              onClick={() => {
                onDeleteReport(props.row.original.id)
              }}>
              Delete
            </Button>
          )}
        </div>
      )
    }
  ]

  if (!currentUserData || reports.loading) {
    return <LoadingSkeleton />;
  }

  return (
    <div className="py-6">
      {isCompanyUser && (
        <div className="mb-4 flex items-center">
          <h1 className="flex-1 text-xl font-semibold">Property Reports</h1>
          <a href="/portal/comp_analysis_reports_new">
            <Button>New Report</Button>
          </a>
        </div>
      )}
      <div>
        <BrynsonTable
          header={() => <></>}
          columns={columns}
          data={reports.data}
          countTitle={"Reports"}
          rowClick={(row) => row}
        />
      </div>
    </div>
  )
}
