import React, { useContext, useState, useMemo } from "react";
import AGGridTable from "../../shared/AGGridTable";
import BrynsonSquareButton from "../../shared/BrynsonSquareButton";
import { GlobalContext } from "../../context/GlobalContext";
import PropertyInfoForm from "./PropertyInfoForm";

const apartmentscom = require("../../../../../public/ils/apartmentscomlogo.png");
const apartmentlist = require("../../../../../public/ils/apartment-list-logo.png");
const zillow = require("../../../../../public/ils/Zillow_logo.png");
const rentcom = require("../../../../../public/ils/rent-com-logo.png");
const zumper = require("../../../../../public/ils/zumper-logo.png");
const rentable = require("../../../../../public/ils/rentable-logo@2x.jpg");
const rentsocial = require("../../../../../public/ils/rent-social-logo.png");
const maxleases = require("../../../../../public/ils/max-leases-logo.png");

const ILSImages = {
  "Apartments.com": <img src={apartmentscom} width="140px" className="object-contain ml-auto mr-auto" alt="Apartments.com" />,
  "Apartment List": <img src={apartmentlist} width="140px" className="object-contain ml-auto mr-auto" alt="Apartment List" />,
  "Rent.com": <img src={rentcom} width="50px" className="object-contain ml-auto mr-auto" alt="Rent.com" />,
  "Zumper": <img src={zumper} width="120px" className="object-contain ml-auto mr-auto" alt="Zumper" />,
  "Zillow": <img src={zillow} width="70px" className="object-contain ml-auto mr-auto" alt="Zillow" />,
  "Rentable": <img src={rentable} width="110px" className="object-contain ml-auto mr-auto" alt="Rentable" />,
  "RentSocial": <img src={rentsocial} width="60px" className="object-contain ml-auto mr-auto" alt="RentSocial" />,
  "Max Leases": <img src={maxleases} width="60px" className="object-contain ml-auto mr-auto" alt="Max Leases" />
};

const PropertyNameCell = (props) => {
  const { value, data, context } = props;
  const showActions = context?.showActions;

  return showActions ? (
    <PropertyInfoForm
      item={data}
      OpenModalComponent={({ onClick }) => (
        <span
          className="font-medium text-bblue underline cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
            onClick();
          }}
        >
          {value}
        </span>
      )}
    />
  ) : (
    <span className="font-medium">{value}</span>
  );
};

export default function PropertiesTable({ properties, propertyDirectoryId = null, splitFee }) {
  const { currentUserData } = useContext(GlobalContext);
  const showActions = currentUserData?.currentUser?.email?.endsWith('@brynson.com');

  const columnDefs = useMemo(() => [
    {
      type: "string",
      title: "Property Name",
      field: "name",
      accessor: "name",
      cellRenderer: "name",
      cellRendererParams: { context: { showActions } },
      pinned: 'left',
      cellClass: "min-w-[200px]",
    },
    { type: "string", title: "Units", accessor: "unitCount", maxWidth: 95 },
    { type: "string", title: "Ownership Group", accessor: "ownershipGroup", wrapHeaderText: true, maxWidth: 130 },
    { type: "string", title: "City", accessor: "city", maxWidth: 100 },
    { type: "string", title: "State", accessor: "state.abbreviation", maxWidth: 100 },
    { type: "string", title: "Region", accessor: "region", maxWidth: 105 },
    {
      type: "group",
      title: ILSImages["Apartments.com"],
      columns: [
        { type: "string", title: "Package", accessor: "currentCostarPackage", maxWidth: 130 },
        { type: "currency", title: "Rate", accessor: "brynsonCostarRate", aggFunc: "sum", maxWidth: 110 },
        { type: "string", title: "Other CoStar Product", accessor: "otherCostarProduct", maxWidth: 130 },
        { type: "currency", title: "Other CoStar Product Rate", accessor: "otherCostarProductBrynsonRate", aggFunc: "sum", maxWidth: 150 },
        { type: "currency", title: "CoStar Rate", accessor: "currentCostarRate", aggFunc: "sum", maxWidth: 100 },
        { type: "currency", title: "State Taxes", accessor: "costarPropertyTaxes", aggFunc: "sum", maxWidth: 100 },
        { type: "string", title: "Changes", accessor: "costarChanges" },
      ]
    },
    {
      type: "group",
      title: ILSImages["Apartment List"],
      columns: [
        { type: "string", title: "Package", accessor: "apartmentListPackage", maxWidth: 130 },
        { type: "currency", title: "Rate", accessor: "brynsonApartmentListRate", aggFunc: "sum", maxWidth: 110},
        { type: "currency", title: "PPL Fee", accessor: "apartmentListPplFee", maxWidth: 110 },
        { type: "currency", title: "Platform Fee", accessor: "apartmentListPlatformFee", aggFunc: "sum", maxWidth: 130},
        { type: "currency", title: "State Taxes", accessor: "apartmentListPropertyTaxes", aggFunc: "sum", maxWidth: 110 },
        { type: "string", title: "Package", accessor: "otherApartmentListProduct", maxWidth: 130 },
        { type: "currency", title: "Rate", accessor: "otherApartmentListProductBrynsonRate", aggFunc: "sum", maxWidth: 110 },
        { type: "string", title: "Changes", accessor: "apartmentListChanges" },
        { type: "integer", title: "Leases", accessor: "apartmentListLeases", maxWidth: 110 },
        { type: "currency", title: "PPL Total", accessor: "apartmentListPplTotal", aggFunc: "sum", maxWidth: 110 },
      ]
    },
    {
      type: "group",
      title: <img src={rentcom} width="40px" className="object-contain ml-auto mr-auto" alt="Rent.com" />,
      columns: [
        {
          accessor: "currentRentComPackage",
          title: "Package",
          type: "string",
          maxWidth: 130
        },
        {
          accessor: "brynsonRentRate",
          title: "Rate",
          type: "currency",
          aggFunc: "sum",
          maxWidth: 110
        },
        {
          accessor: "otherRentComProduct",
          title: "Package",
          type: "string",
          maxWidth: 130
        },
        {
          accessor: "otherRentComProductBrynsonRate",
          title: "Rate",
          type: "currency",
          aggFunc: "sum",
          maxWidth: 110
        },
        {
          accessor: "rentPropertyTaxes",
          title: "State Taxes",
          type: "currency",
          aggFunc: "sum",
          maxWidth: 110
        },
        {
          accessor: "rentComChanges",
          title: "Changes",
          type: "string"
        },
      ]
    },
    {
      type: "group",
      title: <img src={zumper} width="100px" className="object-contain ml-auto mr-auto" alt="Zumper" />,
      columns: [
        {
          accessor: "currentZumperPackage",
          title: "Package",
          type: "string",
          maxWidth: 150,
          maxWidth: 130
        },
        {
          accessor: "brynsonZumperRate",
          title: "Rate",
          type: "currency",
          aggFunc: "sum",
          maxWidth: 110
        },
        {
          accessor: "otherZumperProduct",
          title: "Other Products",
          type: "string",
          maxWidth: 130
        },
        {
          accessor: "otherZumperProductBrynsonRate",
          title: "Other Products Rate",
          type: "currency",
          aggFunc: "sum",
          maxWidth: 110
        },
        {
          accessor: "zumperPropertyTaxes",
          title: "State Taxes",
          type: "currency",
          aggFunc: "sum",
          maxWidth: 110
        },
        {
          accessor: "zumperChanges",
          title: "Changes",
          type: "string"
        },
      ]
    },
    {
      type: "group",
      title: <img src={rentable} width="110px" className="object-contain ml-auto mr-auto" alt="Rentable" />,
      columns: [
        {
          accessor: "currentRentablePackage",
          title: "Package",
          type: "string",
          maxWidth: 130
        },
        {
          accessor: "brynsonRentableRate",
          title: "Rate",
          type: "currency",
          aggFunc: "sum",
          maxWidth: 110
        },
        {
          accessor: "rentablePropertyTaxes",
          title: "State Taxes",
          type: "currency",
          aggFunc: "sum",
          maxWidth: 110
        },
        {
          accessor: "rentableChanges",
          title: "Changes",
          type: "string"
        },
      ]
    },
    {
      type: "group",
      title: <img src={zillow} width="70px" className="object-contain ml-auto mr-auto" alt="Zillow" />,
      columns: [
        {
          accessor: "currentZillowPackage",
          title: "Package",
          type: "string",
          maxWidth: 130,
        },
        {
          accessor: "brynsonZillowRate",
          title: "Rate",
          type: "currency",
          aggFunc: "sum",
          maxWidth: 110
        },
        {
          accessor: "otherZillowProduct",
          title: "Package",
          type: "string",
          maxWidth: 130
        },
        {
          accessor: "otherZillowProductBrynsonRate",
          title: "Rate",
          type: "currency",
          aggFunc: "sum",
          maxWidth: 110
        },
        {
          accessor: "zillowPplFee",
          title: "PPL Rate",
          type: "currency",
          maxWidth: 110
        },
        {
          accessor: "zillowPropertyTaxes",
          title: "State Taxes",
          type: "currency",
          aggFunc: "sum",
          maxWidth: 110
        },
        {
          accessor: "zillowChanges",
          title: "Zillow Changes",
          type: "string"
        },
        {
          accessor: "zillowLeases",
          title: "Leases",
          type: "integer",
          maxWidth: 110
        },
        {
          accessor: "zillowPplTotal",
          title: "PPL Total",
          type: "currency",
          aggFunc: "sum",
          maxWidth: 130
        },
      ]
    },
    {
      type: "group",
      title: <img src={rentsocial} width="60px" className="object-contain ml-auto mr-auto" alt="RentSocial" />,
      columns: [
        {
          accessor: "currentRentSocialPackage",
          title: "Package",
          type: "string",
          maxWidth: 130
        },
        {
          accessor: "brynsonRentSocialRate",
          title: "Rate",
          type: "currency",
          aggFunc: "sum",
          maxWidth: 110
        },
        {
          accessor: "RentSocialPropertyTaxes",
          title: "State Taxes (if applicable)",
          type: "currency",
          aggFunc: "sum",
          maxWidth: 110
        }
      ]
    },
    {
      type: "group",
      title: <img src={maxleases} width="60px" className="object-contain ml-auto mr-auto" alt="Max Leases" />,
      columns: [
        {
          accessor: "currentRentMaxPackage",
          title: "Package",
          type: "string",
          maxWidth: 150
        },
        {
          accessor: "brynsonMaxLeasesRate",
          title: "Rate",
          type: "currency",
          aggFunc: "sum",
          maxWidth: 150
        }
      ]
    },
    {
      type: "currency",
      title: "Brynsights (Per Unit Total)",
      accessor: "brynsightsTotal",
      aggFunc: "sum",
    },
    {
      type: "currency",
      title: "Brynson Fee",
      accessor: "brynsonIlsManagementFee",
      maxWidth: 130
    },
    {
      type: "currency",
      title: splitFee ? "Monthly Property ILS Total" : "Monthly Property Total",
      accessor: "totalIlsSpend",
      cellStyle: { backgroundColor: '#C0CEDA' },
      aggFunc: "sum",
    },
  ], [showActions]);


  const customCellRenderers = useMemo(() => ({
    name: PropertyNameCell,
  }), []);

  const customHeaderRenderers = {};

  return (
    <div>
      {showActions && (
        <div className="mb-4 space-x-4">
          <a
            href="/portal/property_directories/new"
            className="text-bblue underline cursor-pointer"
          >
            Add new property directory
          </a>
          <a
            href={`/portal/property_directories/${propertyDirectoryId}/edit`}
            className="text-bblue underline cursor-pointer"
          >
            Edit property directory
          </a>
        <PropertyInfoForm
          OpenModalComponent={({ onClick }) => (
            <a
              onClick={onClick}
              className="text-bblue underline cursor-pointer"
            >
              Add new property info
            </a>
          )}
        />
        </div>
      )}
      <AGGridTable
        data={properties}
        headerColumns={columnDefs}
        customCellRenderers={customCellRenderers}
        customHeaderRenderers={customHeaderRenderers}
        context={{ showActions }}
        footerRowStyle={{ backgroundColor: '#c89a2c', color: '#fff' }}
      />
    </div>
  );
}
