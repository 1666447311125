import { Button, Card, TextInput } from "flowbite-react"
import React, { useContext, useState } from "react"
import { PlayIcon, Bars3Icon } from '@heroicons/react/24/outline';

import { MediaContext } from "../../context/MediaContext"
import Modal from "../../shared/Modal"
import BrynsonSquareButton from "../BrynsonSquareButton"
import DesktopTitle3 from "../DesktopTitle3"

export const MatterportLogoUrl =
"https://matterport.com/sites/default/files/styles/16_9_card_desktop/public/images/Color_with_White.png?itok=7mE3in8x"

export default function MediumCard({ item, editable, drag, onChange }) {
  const draggable = drag || false
  const [editing, setEditing] = useState(false)
  const [caption, setCaption] = useState(item.caption)
  const [name, setName] = useState(item.name)
  const [showDelete, setShowDelete] = useState(false)
  const { deleteMedium, updateMedium } = useContext(MediaContext)

  const cancelEdit = () => {
    setEditing(false)
  }

  const syncMedium = async () => {
    await updateMedium({
      id: item.id,
      caption: caption,
      rank: item.rank,
      mediumType: item.mediumType,
      name: name
    })
  }

  const switchFeed = async () => {
    const result = await updateMedium({
      id: item.id,
      mediumType: item.mediumType,
      name: item.name,
      caption: item.caption,
      rank: item.rank,
      feedDisabled: !item.feedDisabled
    })

    if (result.success) {
      onChange()
    }
  }

  const renderEditForm = () => (
    <div className="mb-3 flex flex-col">
      <TextInput
        id="photo-name"
        placeholder="Name"
        value={name}
        type="text"
        addon="Name"
        onChange={(input) => {
          setName(input.target.value)
        }}
      />
      <TextInput
        id="photo-caption"
        placeholder="Caption"
        value={caption}
        type="text"
        addon="Caption"
        className="mt-3"
        onChange={(input) => {
          setCaption(input.target.value)
        }}
      />
    </div>
  )

  const renderEditButtons = () => (
    <div className="flex flex-col">
      <BrynsonSquareButton
        type="link"
        size="slim"
        className={"mb-4 w-full"}
        onClick={() => {
          syncMedium()
          cancelEdit()
        }}
        label="Save"
      />
      <BrynsonSquareButton
        type="warning"
        size="slim"
        className={"w-full"}
        onClick={() => {
          cancelEdit()
        }}
        label="Cancel"
      />
    </div>
  )

  const renderFeedSwitch = () => {
    if (item.feedDisabled) {
      return (
        <Button
          size="xs"
          color="blue"
          className={"w-full"}
          onClick={async () => {
            switchFeed()
          }}>
          Enable Feed
        </Button>
      )
    }

    return (
      <Button
        size="xs"
        color="gray"
        className={"w-full"}
        onClick={async () => {
          switchFeed()
        }}>
        Disable Feed
      </Button>
    )
  }

  const renderButtons = () =>
    editable && (
      <div className="flex flex-col">
        {renderFeedSwitch()}
        <Button
          size="xs"
          className={"my-2 w-full"}
          onClick={() => {
            setEditing(true)
          }}>
          Edit
        </Button>
        <Button
          size="xs"
          color="failure"
          className={"w-full"}
          onClick={() => {
            setShowDelete(true)
          }}>
          Delete
        </Button>
      </div>
    )

  const renderDisplay = () => (
    <>
      <div>Name: {item.name}</div>
      <div>Caption: {item.caption}</div>
      <div>For: {item.mediumableType}</div>
    </>
  )

  const renderDeleteModal = () => (
    <Modal
      open={showDelete}
      size="small"
      header={
        <div>
          <DesktopTitle3 content="Delete" />
        </div>
      }
      footer={
        <>
          <BrynsonSquareButton
            type="link"
            size="medium"
            onClick={() => {
              setShowDelete(false)
            }}
            label="Go Back"
          />
          <BrynsonSquareButton
            type="warning"
            size="medium"
            loading={false}
            className={"flex items-center justify-center"}
            onClick={() => {
              deleteMedium({ id: item.id })
              onChange()
              setShowDelete(false)
            }}
            label="Delete"
          />
        </>
      }>
      <DesktopTitle3 content="Are you sure you want to delete? This action can't be undone." />
    </Modal>
  )

  return (
    <Card className="m-1" horizontal>
      <div className="flex flex-row gap-3">
        <div className="flex basis-1/12 flex-col items-center justify-center text-2xl font-bold">
          {draggable && <Bars3Icon className="cursor-move h-5 w-5" />}
        </div>
        <div className="flex basis-11/12 flex-col items-center justify-center gap-2">
          {item.mediumType === "video" ? (
            <a href={item.medium?.url} alt={item.caption} target="_blank" rel="noreferrer">
              <PlayIcon className="text-[120px] h-5 w-5" />
            </a>
          ) : (
            <a
              href={item.mediumType === "matterport" ? item.matterportUrl : item.medium?.url}
              alt={item.caption}
              target="_blank"
              rel="noreferrer">
              <img
                className="max-h-32 max-w-[32] rounded-lg object-cover shadow-md"
                src={item.mediumType === "matterport" ? MatterportLogoUrl : item.medium?.url}
                alt={item.caption}
              />
            </a>
          )}
        </div>
      </div>
      <div className="flex flex-row gap-3">
        <div className="basis-6/12 flex-row justify-end">
          <div className="flex flex-col">{editing ? renderEditForm() : renderDisplay()}</div>
        </div>
        <div className="basis-6/12 flex-row justify-end">
          <div className="flex flex-col">{editing ? renderEditButtons() : renderButtons()}</div>
        </div>
      </div>
      {renderDeleteModal()}
    </Card>
  )
}
