export const CLIENT_ACTIVITIES_QUERY = `
  query {
    clientActivities {
      id
      auditComments
      companyId
      confirmedReceiptByIlsDate
      currentPackage
      currentPrice
      dateInitiatedByBrynson
      dateRequested
      discountedCurrentPrice
      discountedNewPrice
      ils
      ilsRepRequestSentToName
      ilsSignedAgreementCompletedDate
      liveDateOnOffIls
      newPackage
      newPrice
      notes
      propertyId
      propertyName
      requestedBy
      requestedEffectiveDate
      requestedType
      userId
    }
  }
`

export const CREATE_CLIENT_ACTIVITY_MUTATITION = `
  mutation CreateClientActivityMutation(
    $propertyId:ID,
    $userId:ID,
    $dateRequested:String!,
    $requestedBy:String,
    $propertyName:String,
    $ils:String,
    $currentPackage:String,
    $currentPrice:Float,
    $discountedCurrentPrice:Float,
    $newPrice:Float,
    $newPackage:String,
    $discountedNewPrice:Float,
    $requestedEffectiveDate:String,
    $requestedType:String,
    $dateInitiatedByBrynson:String,
    $ilsRepRequestSentToName:String,
    $confirmedReceiptByIlsDate:String,
    $ilsSignedAgreementCompletedDate:String,
    $liveDateOnOffIls:String,
    $notes:String,
    $auditComments:String,
  ) {
    createClientActivityMutation(
      propertyId: $propertyId,
      userId: $userId,
      dateRequested: $dateRequested,
      requestedBy: $requestedBy,
      propertyName: $propertyName,
      ils: $ils,
      currentPackage: $currentPackage,
      currentPrice: $currentPrice,
      discountedCurrentPrice: $discountedCurrentPrice,
      newPrice: $newPrice,
      newPackage: $newPackage,
      discountedNewPrice: $discountedNewPrice,
      requestedEffectiveDate: $requestedEffectiveDate,
      requestedType: $requestedType,
      dateInitiatedByBrynson: $dateInitiatedByBrynson,
      ilsRepRequestSentToName: $ilsRepRequestSentToName,
      confirmedReceiptByIlsDate: $confirmedReceiptByIlsDate,
      ilsSignedAgreementCompletedDate: $ilsSignedAgreementCompletedDate,
      liveDateOnOffIls: $liveDateOnOffIls,
      notes: $notes,
      auditComments: $auditComments,
    ) {
      id
      propertyId
      companyId
      userId
      dateRequested
      requestedBy
      propertyName
      ils
      currentPackage
      currentPrice
      discountedCurrentPrice
      newPrice
      newPackage
      discountedNewPrice
      requestedEffectiveDate
      requestedType
      dateInitiatedByBrynson
      ilsRepRequestSentToName
      confirmedReceiptByIlsDate
      ilsSignedAgreementCompletedDate
      liveDateOnOffIls
      notes
      auditComments
    }
  }
`

export const UPDATE_CLIENT_ACTIVITY_MUTATITION = `
  mutation UpdateClientActivityMutation(
    $id:ID!,
    $propertyId:ID,
    $userId:ID,
    $dateRequested:String!,
    $requestedBy:String,
    $propertyName:String,
    $ils:String,
    $currentPackage:String,
    $currentPrice:Float,
    $discountedCurrentPrice:Float,
    $newPrice:Float,
    $newPackage:String,
    $discountedNewPrice:Float,
    $requestedEffectiveDate:String,
    $requestedType:String,
    $dateInitiatedByBrynson:String,
    $ilsRepRequestSentToName:String,
    $confirmedReceiptByIlsDate:String,
    $ilsSignedAgreementCompletedDate:String,
    $liveDateOnOffIls:String,
    $notes:String,
    $auditComments:String,
  ) {
    updateClientActivityMutation(
      id: $id,
      propertyId: $propertyId,
      userId: $userId,
      dateRequested: $dateRequested,
      requestedBy: $requestedBy,
      propertyName: $propertyName,
      ils: $ils,
      currentPackage: $currentPackage,
      currentPrice: $currentPrice,
      discountedCurrentPrice: $discountedCurrentPrice,
      newPrice: $newPrice,
      newPackage: $newPackage,
      discountedNewPrice: $discountedNewPrice,
      requestedEffectiveDate: $requestedEffectiveDate,
      requestedType: $requestedType,
      dateInitiatedByBrynson: $dateInitiatedByBrynson,
      ilsRepRequestSentToName: $ilsRepRequestSentToName,
      confirmedReceiptByIlsDate: $confirmedReceiptByIlsDate,
      ilsSignedAgreementCompletedDate: $ilsSignedAgreementCompletedDate,
      liveDateOnOffIls: $liveDateOnOffIls,
      notes: $notes,
      auditComments: $auditComments,
    ) {
      id
      propertyId
      companyId
      userId
      dateRequested
      requestedBy
      propertyName
      ils
      currentPackage
      currentPrice
      discountedCurrentPrice
      newPrice
      newPackage
      discountedNewPrice
      requestedEffectiveDate
      requestedType
      dateInitiatedByBrynson
      ilsRepRequestSentToName
      confirmedReceiptByIlsDate
      ilsSignedAgreementCompletedDate
      liveDateOnOffIls
      notes
      auditComments
    }
  }
`