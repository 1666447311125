import React, { useContext } from "react"
import { useQuery } from "urql"

import { GlobalContext } from "../../context/GlobalContext"
import { DCAAS_QUERY } from "../../queries/DcaasMutations"
import BrynsonTable from "../../shared/BrynsonTable"

import SpecialProperty from "./SpecialProperty"

export default function DCAAS() {
  const [{ data: dcaasData }] = useQuery({
    query: DCAAS_QUERY
  })

  const special_properties = [
    "Goldfarb Properties",
    "Chelsea Management",
    "Prime Management LLC",
    "Dawn Homes Management Properties",
    "Bellrock Real Estate Partners LLC",
    "Tri City Rentals",
    "AMBO Properties",
    "Finning Properties",
    "Aberly Management",
    "Judge Development",
    "Olive Tree Management",
    "Olympus Property",
    "M3 Management",
    "WPHS Venture Partners",
    "Westmere Village",
    "REM Capital",
    "Skytian Capital",
    "Omni Development",
    "PMC Demo",
    "Bruns Realty Group",
    "The Sterling Group",
    "Velo Residential",
    "FLATS",
    'Magnolia Management'
 ]

  const { currentUserData } = useContext(GlobalContext)
  const about = currentUserData?.currentUser?.currentCompany?.about

  const columns = [
    { Header: "Property", accessor: "property.name" },
    { Header: "Auditor Name", accessor: "auditorName" },
    { Header: "Address", accessor: "address" },
    { Header: "Website", accessor: "website" },
    { Header: "Phone", accessor: "directPhoneNumber" },
    { Header: "Email", accessor: "email" }
  ]

  const dcaasWithName = dcaasData?.dcaas.map((dcaa) => ({
    ...dcaa,
    name: dcaa.property.name
  }))

  if (special_properties.includes(about)) {
    return <SpecialProperty about={about} />
  }

  if (dcaasData?.dcaas) {
    return (
      <div className="p-8">
        <BrynsonTable
          header={"DCAAs"}
          countTitle={dcaasData.dcaas.length > 1 ? "DCAAs" : "DCAA"}
          columns={columns}
          data={dcaasWithName}
          rowClick={(row) => {
            window.location.href = `/portal/dcaas/${row.id}`
          }}
        />
      </div>
    )
  }
  return <div>Loading...</div>
}
