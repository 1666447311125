import { Table, Tooltip } from "flowbite-react"
import React from "react"
import { InformationCircleIcon } from '@heroicons/react/24/outline';

import RenderQuality from "./RenderQuality"

export function Finishes({ properties = [] }) {
  const getOverAll = (quality) => {
    const scores = [
      quality.avg_quality_score_bedroom,
      quality.avg_quality_score_kitchen,
      quality.avg_quality_score_bathroom,
      quality.avg_quality_score_laundry_room,
      quality.avg_quality_score_living_room,
      quality.avg_quality_score_swimming_pool,
      quality.avg_quality_score_dining_room,
      quality.avg_quality_score_common_areas
    ].filter(score => score !== undefined && score !== null);

    if (scores.length === 0) return 0;

    const sum = scores.reduce((acc, score) => acc + score, 0);
    return Math.round((sum / scores.length) * 10);
  }


  const formatsFinishes = (properties) => {
    return properties.map((property) => ({
      property: property.building_name,
      bedroom: Math.round((property.building_quality.avg_quality_score_bedroom || 0) * 10),
      kitchen: Math.round((property.building_quality.avg_quality_score_kitchen || 0) * 10),
      bathroom: Math.round((property.building_quality.avg_quality_score_bathroom || 0) * 10),
      laundry_room: Math.round((property.building_quality.avg_quality_score_laundry_room || 0) * 10),
      living_room: Math.round((property.building_quality.avg_quality_score_living_room || 0) * 10),
      swimming_pool: Math.round((property.building_quality.avg_quality_score_swimming_pool || 0) * 10),
      dining_room: Math.round((property.building_quality.avg_quality_score_dining_room || 0) * 10),
      common_areas: Math.round((property.building_quality.avg_quality_score_common_areas || 0) * 10),
      overall: getOverAll(property.building_quality)
    }));
  }

  const finishes = formatsFinishes(properties)

  return (
    <>
      <FinishesTable finishes={finishes} />
    </>
  )
}

export function FinishesTable({ finishes = [] }) {
  function v(value) {
    return typeof value === "number" ? value : 0
  }

  const subject = finishes.find((finish) => finish.property)
  const totalFinishes = finishes.reduce(
    (acc, finish) => {
      acc.overall += v(finish.overall)
      acc.bedroom += v(finish.bedroom)
      acc.kitchen += v(finish.kitchen)
      acc.bathroom += v(finish.bathroom)
      acc.laundry_room += v(finish.laundry_room)
      acc.living_room += v(finish.living_room)
      acc.swimming_pool += v(finish.swimming_pool)
      acc.dining_room += v(finish.dining_room)
      acc.common_areas += v(finish.common_areas)
      return acc
    },
    {
      overall: 0,
      bedroom: 0,
      kitchen: 0,
      bathroom: 0,
      laundry_room: 0,
      living_room: 0,
      swimming_pool: 0,
      dining_room: 0,
      common_areas: 0
    }
  )

  const count = finishes.length
  const average = {
    overall: count > 0 ? totalFinishes.overall / count : 0,
    bedroom: count > 0 ? totalFinishes.bedroom / count : 0,
    kitchen: count > 0 ? totalFinishes.kitchen / count : 0,
    bathroom: count > 0 ? totalFinishes.bathroom / count : 0,
    laundry_room: count > 0 ? totalFinishes.laundry_room / count : 0,
    living_room: count > 0 ? totalFinishes.living_room / count : 0,
    swimming_pool: count > 0 ? totalFinishes.swimming_pool / count : 0,
    dining_room: count > 0 ? totalFinishes.dining_room / count : 0,
    common_areas: count > 0 ? totalFinishes.common_areas / count : 0
  }

  return (
    <Table>
      <Table.Head>
        <Table.HeadCell>Finishes</Table.HeadCell>
        <Table.HeadCell>Overall</Table.HeadCell>
        <Table.HeadCell>Bedroom</Table.HeadCell>
        <Table.HeadCell>Kitchen</Table.HeadCell>
        <Table.HeadCell>Bathroom</Table.HeadCell>
        <Table.HeadCell>Laundry Room</Table.HeadCell>
        <Table.HeadCell>Living Room</Table.HeadCell>
        <Table.HeadCell>Swimming Pool</Table.HeadCell>
        <Table.HeadCell>Dining Room</Table.HeadCell>
        <Table.HeadCell>Common Areas</Table.HeadCell>
      </Table.Head>
      <Table.Body className="border-t text-sm">
        {finishes.map((item, index) => (
          <Table.Row
            key={index}
            className={`border-b hover:bg-blue-100 ${index === 0 ? "bg-blue-50 font-semibold text-blue-800" : "font-medium text-gray-800"
              }`}>
            <Table.Cell
              title={item.property}
              className="max-w-[10rem] overflow-hidden text-ellipsis whitespace-nowrap p-0 py-2 pl-4">
              {item.property}
            </Table.Cell>
            <Table.Cell className="p-0 text-center align-middle">
              <RenderQuality item={item.overall} />
            </Table.Cell>
            <Table.Cell className="p-0 text-center align-middle">
              <RenderQuality item={item.bedroom} />
            </Table.Cell>
            <Table.Cell className="p-0 text-center align-middle">
              <RenderQuality item={item.kitchen} />
            </Table.Cell>
            <Table.Cell className="p-0 text-center align-middle">
              <RenderQuality item={item.bathroom} />
            </Table.Cell>
            <Table.Cell className="p-0 text-center align-middle">
              <RenderQuality item={item.laundry_room} />
            </Table.Cell>
            <Table.Cell className="p-0 text-center align-middle">
              <RenderQuality item={item.living_room} />
            </Table.Cell>
            <Table.Cell className="p-0 text-center align-middle">
              <RenderQuality item={item.swimming_pool} />
            </Table.Cell>
            <Table.Cell className="p-0 text-center align-middle">
              <RenderQuality item={item.dining_room} />
            </Table.Cell>
            <Table.Cell className="p-0 text-center align-middle">
              <RenderQuality item={item.common_areas} />
            </Table.Cell>
          </Table.Row>
        ))}
        <Table.Row className="border-t bg-gray-50 font-semibold text-gray-800">
          <Table.Cell className="flex items-center p-0 py-1.5 pl-4">
            Comp Average
            <Tooltip className="normal-case" content={`Does not include ${subject?.property}`} placeholder="bottom">
              <InformationCircleIcon className="ml-1 mt-0.5 h-3 w-3 text-gray-700" />
            </Tooltip>
          </Table.Cell>
          {Object.keys(average).map((key, index) => (
            <Table.Cell key={index} className="p-0 text-center align-middle">
              {`${Math.round(average[key])}/10`}
            </Table.Cell>
          ))}

          <Table.Cell className="p-0 text-center align-middle"></Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  )
}
