import React from "react"
import MaskedInput from "react-text-mask"
import styled from "styled-components"
import createNumberMask from "text-mask-addons/dist/createNumberMask"

const InputWrapper = styled.div`
  input {
    border: 1px solid #c8d6df;
    background: white;
    padding: 14px 16px;
    width: 100%;
    margin: 0;
    outline: 0;
    box-shadow: none;
    border-radius: 0px;
    height: auto;
    font-size: 16px;
  }

  input::placeholder {
    font-size: 16px;
    font-weight: 400;
  }
`

const defaultMaskOptions = {
  prefix: "$",
  suffix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ",",
  allowDecimal: true,
  decimalSymbol: ".",
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 7, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
  allowNegative: true,
}

const CurrencyInput = ({ maskOptions, ...inputProps }) => {
  const currencyMask = createNumberMask({
    ...defaultMaskOptions
  })

  return (
    <InputWrapper>
      <MaskedInput mask={currencyMask} {...inputProps} />
    </InputWrapper>
  )
}

export default CurrencyInput
