import { Button, Checkbox, Label, Table, TextInput } from "flowbite-react"
import React, { useContext, useEffect, useMemo, useState } from "react"
import { PlayIcon } from '@heroicons/react/24/outline';

import { GlobalContext } from "../context/GlobalContext"
import { MediaContext } from "../context/MediaContext"

import BreadCrumbs from "./BreadCrumbs"
import DesktopTitle1 from "./DesktopH5"
import Loading from "./Loading"
import { MatterportLogoUrl } from "./Media/MediumCard"
import MediumItem from "./Media/MediumItem"
import { MediaTypes } from "./MediaIndex"
import MediumUpload from "./MediumUpload"
import Sidebar from "./Sidebar"

function MediumLink({ medium }) {
  return (
    <div className="flex items-center justify-center">
      {medium.mediumType === "video" ? (
        <a
          href={medium.medium?.url}
          alt={medium.caption}
          target="_blank"
          rel="noreferrer"
          className="flex w-full items-center justify-center rounded-md border py-8 shadow-md">
          <PlayIcon className="text-[120px]" />
        </a>
      ) : (
        <a
          href={medium.mediumType === "matterport" ? medium.matterportUrl : medium.medium?.url}
          alt={medium.caption}
          target="_blank"
          rel="noreferrer">
          <img
            className="rounded-lg object-cover shadow-md"
            src={medium.mediumType === "matterport" ? MatterportLogoUrl : medium.medium?.url}
            alt={medium.caption}
          />
        </a>
      )}
    </div>
  )
}

function MediumForm({ medium, className = "", onChange = () => {} }) {
  return (
    <div className={className}>
      <MediumLink medium={medium} />
      <form className="mt-6">
        <div className="mb-6 grid grid-cols-2 gap-4">
          <div>
            <Label htmlFor="medium_name" value="Name" />
            <TextInput
              type="text"
              id="medium_name"
              value={medium.name || ""}
              onChange={(e) => {
                onChange({ ...medium, name: e.target.value })
              }}
            />
          </div>
          <div>
            <Label htmlFor="medium_caption" value="Caption" />
            <TextInput
              type="text"
              id="medium_caption"
              value={medium.caption || ""}
              onChange={(e) => {
                onChange({ ...medium, caption: e.target.value })
              }}
            />
          </div>
          <div>
            <Label htmlFor="medium_rank" value="Rank" />
            <TextInput
              type="number"
              id="medium_rank"
              value={medium.rank || ""}
              onChange={(e) => {
                onChange({ ...medium, rank: e.target.value })
              }}
            />
          </div>
        </div>
      </form>
    </div>
  )
}

function FloorplansAndUnitList({ floorplans = [], onSelect = () => {} }) {
  const [expanded, setExpanded] = useState()

  return (
    <Table>
      <Table.Head>
        <Table.HeadCell>ID</Table.HeadCell>
        <Table.HeadCell>Floorplan name</Table.HeadCell>
        <Table.HeadCell>Units</Table.HeadCell>
        <Table.HeadCell></Table.HeadCell>
        <Table.HeadCell></Table.HeadCell>
      </Table.Head>
      <Table.Body className="divide-y">
        {floorplans && floorplans.length > 0 ? (
          floorplans.map((floorplan) => (
            <>
              <Table.Row key={floorplan.id} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                <Table.Cell className="whitespace-nowrap py-3 align-middle font-medium text-gray-600 dark:text-white">
                  {floorplan.id}
                </Table.Cell>
                <Table.Cell className="py-3 align-middle font-semibold text-gray-800">{floorplan.name}</Table.Cell>
                <Table.Cell className="py-3 align-middle font-medium text-gray-800">
                  {floorplan.units.length}
                </Table.Cell>
                <Table.Cell className="py-3 align-middle">
                  <Button size="sm" className="!py-0" onClick={() => onSelect(floorplan.id, "Floorplan")}>
                    Assign
                  </Button>
                </Table.Cell>
                <Table.Cell
                  className="cursor-pointer py-3 text-right align-middle text-xl font-semibold text-blue-600"
                  onClick={() => setExpanded(expanded === floorplan ? null : floorplan)}>
                  <span className="whitespace-nowrap text-xs">
                    {expanded === floorplan ? "Hide Units" : "Show Units"}
                  </span>
                </Table.Cell>
              </Table.Row>
              {expanded === floorplan && (
                <Table.Row key={floorplan.id + "expanded"} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                  <Table.Cell colSpan="5">
                    <table className="w-full" key={"unitTable-for-floorplan" + floorplan.id}>
                      <Table.Head>
                        <Table.HeadCell>ID</Table.HeadCell>
                        <Table.HeadCell>Unit name</Table.HeadCell>
                        <Table.HeadCell></Table.HeadCell>
                      </Table.Head>
                      <Table.Body className="w-full divide-y">
                        {floorplan.units && floorplan.units.length > 0 ? (
                          floorplan.units.map((unit) => {
                            const maxLength = Math.max(...floorplan.units.map((unit) => unit.name.length))
                            return (
                              <Table.Row
                                key={unit.id + "unit"}
                                className="border-white bg-slate-100 transition hover:bg-slate-200">
                                <Table.Cell className="whitespace-nowrap py-2 align-middle font-medium text-gray-500 dark:text-white">
                                  {unit.id}
                                </Table.Cell>
                                <Table.Cell className="py-2 align-middle font-semibold text-gray-700">
                                  <pre className="bg-inherit p-0">{unit.name.padStart(maxLength, " ")}</pre>
                                </Table.Cell>
                                <Table.Cell className="flex items-center justify-end py-2">
                                  <Button size="xs" className="!py-0" onClick={() => onSelect(unit.id, "Unit")}>
                                    Assign
                                  </Button>
                                </Table.Cell>
                              </Table.Row>
                            )
                          })
                        ) : (
                          <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800" key={"no-result"}>
                            <Table.Cell colSpan="4" className="text-center">
                              No units available
                            </Table.Cell>
                          </Table.Row>
                        )}
                      </Table.Body>
                    </table>
                  </Table.Cell>
                </Table.Row>
              )}
            </>
          ))
        ) : (
          <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell colSpan="5" className="text-center">
              No floorplans available
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  )
}

export default function MediaAll({ params, filestackApiKey }) {
  const id = parseInt(JSON.parse(params)["id"])
  const source = JSON.parse(params)["source"] || "Property"
  const isProperty = source === "Property"

  const title = isProperty ? "All Media" : "List of Media"
  const mediaTypes = Object.keys(MediaTypes).filter((type) => type !== "matterport")

  const { propertyData, reloadProperty } = useContext(GlobalContext)
  // eslint-disable-next-line no-unused-vars
  const { createMedium: create, sortMedia, updateMedium, bulkUpdateMediaMutation } = useContext(MediaContext)

  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState()
  const [media, setMedia] = useState([])
  const [medium, setMedium] = useState()
  const [showSidebar, setShowSidebar] = useState(false)
  const [breadCrumbs, setBreadCrumbs] = useState()
  const [mediumType, setMediumType] = useState("photo")
  const [imageLoading, setImageLoading] = useState(false)
  const [timeoutId, setTimeoutId] = useState()

  const property = propertyData?.property
  const floorplans = property?.floorplans
  const selectedMediaCount = media.filter((meidum) => meidum.selected).length

  const handleMediumUpdate = (medium) => {
    setMedium(medium)

    timeoutId && clearTimeout(timeoutId)
    const newTimeoutId = setTimeout(() => {
      updateMedium({
        id: medium.id,
        caption: medium.caption,
        rank: medium.rank,
        mediumType: medium.mediumType,
        name: medium.name
      })
    }, 2000)

    setTimeoutId(newTimeoutId)
  }

  useMemo(() => {
    if (propertyData && property) {
      setMedia(
        [
          ...property.media.reverse().map((item) => ({ ...item, owner: property })),
          ...property.units.map((unit) => unit.media.map((item) => ({ ...item, owner: unit }))).flat(),
          ...property.floorplans
            .map((floorplan) => floorplan.media.map((item) => ({ ...item, owner: floorplan })))
            .flat()
        ]
          .map((medium) => ({ ...medium, selected: false }))
          .filter((item) => item.mediumType !== "matterport")
      )

      const breadCrumbs = [
        { link: "/portal/dashboard", title: "dashboard" },
        { link: "/portal/listings", title: `Property ${property?.name || ""}` },
        { link: null, title }
      ]
      setBreadCrumbs(breadCrumbs)
    }
  }, [propertyData])

  useEffect(() => {
    if (params && !breadCrumbs) {
      reloadProperty(id)
    }
  }, [])

  const onAssign = ({ mediumableId, mediumableType }) => {
    const mediaIds = media.filter((medium) => medium.selected).map((medium) => medium.id)
    bulkUpdateMediaMutation({
      mediaIds,
      mediumableId,
      mediumableType
    }).then((result) => {
      if (result.error) {
        setError(result.error)
      } else {
        reloadProperty(id)
      }
    })
  }

  const MediaTypeSelector = () => (
    <div className="basis-1/5 gap-2">
      {mediaTypes.map((item) => (
        <Button
          className="mb-2"
          color={item === mediumType ? "blue" : "gray"}
          key={item}
          pill
          onClick={() => {
            setMediumType(item)
          }}>
          {MediaTypes[item].label}
        </Button>
      ))}
    </div>
  )

  const onSelectMedium = (selected, index, medium) => {
    setMedia([...media.slice(0, index), { ...medium, selected }, ...media.slice(index + 1)])
  }

  return (
    <>
      {breadCrumbs ? (
        <div className="p-8">
          {breadCrumbs && <BreadCrumbs items={breadCrumbs} />}
          <div className="flex items-center space-x-4">
            <DesktopTitle1 content={title} />
            <a
              className="mb-6 mt-1 inline-block border-blue-600 text-sm font-semibold text-blue-600"
              href={`/portal/media?id=${id}&source=Property`}>
              View Property Specific Media
            </a>
          </div>
          {error && <p className="text-red-600">{error}</p>}
          <div className="flex flex-row gap-3">
            <div className="basis-1/5 gap-2">
              <MediaTypeSelector />
            </div>
            <div className="flex basis-4/5 flex-col">
              <div className="flex max-w-3xl flex-row">
                <MediumUpload
                  id={id}
                  callback={() => reloadProperty(id)}
                  loading={imageLoading}
                  setLoading={setImageLoading}
                  fileType={MediaTypes[mediumType].fileType}
                  mediumType={mediumType}
                  sourceId={id}
                  sourceType={source}
                  apikey={filestackApiKey}
                />
              </div>
              <div className="mt-4 max-w-3xl">
                <div className="mb-3 flex items-center justify-end gap-4">
                  <Label className="mb-0 mr-4 flex cursor-pointer select-none items-center space-x-2">
                    <Checkbox
                      checked={selectedMediaCount > 0 && selectedMediaCount === media.length}
                      onChange={(e) => {
                        setMedia(media.map((medium) => ({ ...medium, selected: e.target.checked })))
                      }}
                      className="checked:bg-cyan-600"
                    />
                    <span>Select All</span>
                  </Label>
                  <Button
                    disabled={selectedMediaCount === 0}
                    onClick={() => setMedia(media.map((medium) => ({ ...medium, selected: false })))}>
                    Unselect
                  </Button>
                  <Button
                    disabled={selectedMediaCount === 0}
                    onClick={() => onAssign({ mediumableId: id, mediumableType: "Property" })}>
                    Assign to current property
                  </Button>
                  <Button disabled={selectedMediaCount === 0} onClick={() => setShowSidebar(true)}>
                    Assign to a floorplan or unit
                  </Button>
                </div>
                {media.length > 0 ? (
                  <div className="grid grid-cols-3 gap-4">
                    {media.map((medium, index) => (
                      <MediumItem
                        key={medium.id}
                        index={index}
                        medium={medium}
                        source={source}
                        onCheck={onSelectMedium}
                        onClick={() => {
                          if (selectedMediaCount === 0) {
                            setMedium(medium)
                            setShowSidebar(true)
                          } else {
                            onSelectMedium(!medium.selected, index, medium)
                          }
                        }}
                      />
                    ))}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loading />
      )}

      <Sidebar
        visible={showSidebar}
        backgroundColor="#FFF"
        iconName="close"
        closeSidebar={() => {
          setShowSidebar(false)
        }}>
        {selectedMediaCount > 0 ? (
          <FloorplansAndUnitList
            floorplans={floorplans}
            onSelect={(mediumableId, mediumableType) => onAssign({ mediumableId, mediumableType })}
          />
        ) : medium ? (
          <MediumForm medium={medium} onChange={handleMediumUpdate} />
        ) : null}
      </Sidebar>
    </>
  )
}
