import React, { useState, useContext } from 'react';
import { useMutation, useQuery } from 'urql';
import { GlobalContext } from "../../context/GlobalContext";
import DataFrame from '../../paul/DataFrame';
import SavedPaulChart from '../../paul/SavedPaulChart';
import BrynsonTabs from "../../shared/BrynsonTabs"
import { v4 as uuidv4 } from 'uuid';
import { useTrackComponentView } from '../../IntercomTracker';


const SAVE_CHART_MUTATION = `
  mutation SavePaulChartMutation($sql: String!, $chartType: String!, $title: String!) {
    savePaulChartMutation(sql: $sql, chartType: $chartType, title: $title) {
      result
      errors
    }
  }
`;

const SAVE_MESSAGE_MUTATION = `
  mutation SavePaulMessageMutation($sessionId: String!, $message: String, $sql: String, $error: String, $sender: String, $lastQuestion: String) {
    savePaulMessageMutation(sessionId: $sessionId, message: $message, sql: $sql, error: $error, sender: $sender, lastQuestion: $lastQuestion) {
      result
      errors
    }
  }
`;

export const PROPERTIES_NAMES_QUERY = `
  query {
    properties {
      name
    }
  }
`


const tabs = [
  {
    label: "Ask Paul",
  },
  {
    label: "Saved Charts",
  },
];

const getLastUserMessage = (messages) => {
  const lastUserMessage = messages
    .filter(message => message.sender === 'user')
    .slice(-1)[0];

  return lastUserMessage ? lastUserMessage.text : '';
};


const Paul = ({ savedCharts }) => {
  const [{ data: propertiesNamesData }] = useQuery({
    query: PROPERTIES_NAMES_QUERY
  })

  const { currentUserData } = useContext(GlobalContext);

  const fullName = currentUserData?.currentUser?.name;
  const firstName = fullName?.split(' ')[0];
  const about = currentUserData?.currentUser?.currentCompany?.about;

  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [lastQuestion, setLastQuestion] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [, savePaulChart] = useMutation(SAVE_CHART_MUTATION);
  const [, savePaulMessage] = useMutation(SAVE_MESSAGE_MUTATION);

  const [sessionId, setSessionId] = useState(() => {
    return uuidv4();
  });

  const handleInputChange = (e) => setInputMessage(e.target.value);

  const handleSaveChart = (sql, chartType) => {
    const title = getLastUserMessage(messages)

    setIsLoading(true);
    savePaulChart({ sql, chartType, title }).then((result) => {
      console.log("GraphQL Response:", result);
      setIsLoading(false);
      if (result.error) {
        // This handles network errors or major operational issues
        console.error('GraphQL Operation Error:', result.error);

      } else if (result.data && result.data.savePaulChartMutation.errors.length > 0) {
        // This handles GraphQL errors, such as validation errors
        console.error('Validation Errors:', result.data.savePaulChartMutation.errors);
      } else if (result.data && result.data.savePaulChartMutation.result === 'success') {
        // Successfully saved
        console.log('Chart successfully saved');
        setMessages(prevMessages => [...prevMessages, { text: 'Great, your chart has been saved to the other tab! If you refresh the page, you can view it there.', sender: 'bot', type: 'text' }]);
      } else {
        // Handle other outcomes
        console.error('Unexpected result:', result.data.savePaulChartMutation.result);
      }
    }).catch(error => {
      // Catch and log any errors that occur during the fetch operation
      console.error('Error during save operation:', error);
      setIsLoading(false);
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inputMessage.trim()) return;

    // Add user message to chat
    setMessages([...messages, { text: inputMessage, sender: 'user', type: 'text' }]);
    setInputMessage('');
    setIsLoading(true);

    savePaulMessage({ sessionId: sessionId, message: inputMessage, sender: 'user', lastQuestion: lastQuestion }).then((result) => {
      if (result.error){
        console.error('GraphQL Operation Error:', result.error);
      } else if (result.data && result.data.savePaulMessageMutation.errors.length > 0) {
        // This handles GraphQL errors, such as validation errors
        console.error('Validation Errors:', result.data.savePaulMessageMutation.errors);
      } else {
        console.log('message logged')
      }
    })

    try {
      const token = document.querySelector('meta[name="csrf-token"]').content;

      // Send message to API
      const response = await fetch('/chats/ask', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': token
        },
        // body: JSON.stringify({ question: inputMessage, last_question: lastQuestion, compact_mode: false }),
        body: JSON.stringify({ question: inputMessage, compact_mode: false }),
      });

      const data = await response.json();
      console.log('data')
      console.log(data)


      if (data.question) {
        setLastQuestion(data.question)
      }
      // Add API response to chat
      if (data.hasOwnProperty('df')) {
        setMessages(prevMessages => [...prevMessages, { text: 'Here is your data in a table:', sender: 'bot', type: 'text' }]);
        savePaulMessage({ sessionId: sessionId, message: 'Here is your data in a table:', sender: 'bot' }).then((result) => {
          if (result.error){
            console.error('GraphQL Operation Error:', result.error);
          } else if (result.data && result.data.savePaulMessageMutation.errors.length > 0) {
            // This handles GraphQL errors, such as validation errors
            console.error('Validation Errors:', result.data.savePaulMessageMutation.errors);
          } else {
            console.log('message logged')
          }
        })
        setMessages(prevMessages => [...prevMessages, { text: data.df, sql: data.team_sql, sender: 'bot', type: 'table' }]);
        savePaulMessage({ sessionId: sessionId, sql: data.team_sql, sender: 'bot' }).then((result) => {
          if (result.error){
            console.error('GraphQL Operation Error:', result.error);
          } else if (result.data && result.data.savePaulMessageMutation.errors.length > 0) {
            // This handles GraphQL errors, such as validation errors
            console.error('Validation Errors:', result.data.savePaulMessageMutation.errors);
          } else {
            console.log('message logged')
          }
        })
      } else {
        setMessages(prevMessages => [...prevMessages, { text: data.message, sender: 'bot', type: 'text' }]);
        savePaulMessage({ sessionId: sessionId, sql: data.team_sql, sender: 'bot' }).then((result) => {
          if (result.error){
            console.error('GraphQL Operation Error:', result.error);
          } else if (result.data && result.data.savePaulMessageMutation.errors.length > 0) {
            // This handles GraphQL errors, such as validation errors
            console.error('Validation Errors:', result.data.savePaulMessageMutation.errors);
          } else {
            console.log('message logged')
          }
        })
      }
    } catch (error) {
      console.error('Error:', error);
      setMessages(prevMessages => [...prevMessages, { text: 'Sorry, there was an error processing your request.', sender: 'bot', type: 'text' }]);
      savePaulMessage({ sessionId: sessionId, message: 'Sorry, there was an error processing your request.', sender: 'bot', error: error }).then((result) => {
        console.log('message logged')
      })
    } finally {
        setIsLoading(false); // Set loading to false
    }
  };

  useTrackComponentView('paul_page');

  return (
    <div className="p-8">
      <BrynsonTabs className="mt-4" tabs={tabs}>
        <div className="flex justify-center">
          <div className="w-2/3 min-h-96 h-full flex flex-col">
            <div>
              <h1 className="font-semibold text-2xl mt-4 text-center flex justify-center">Ask Paul<span className="text-xs ml-1 text text-bgold">BETA</span></h1>
              <h1 className="font-medium italic text-lg mb-6 mt-2 text-center">About your data in Brynsights™</h1>
            </div>
            <div className="flex-grow overflow-y-auto px-4 pt-4 pb-8 font-medium">
              {messages.length === 0 ? (
                <div className="flex justify-center">
                  <div className="max-w-[1200px] text-xl text-navy">
                    <div className="">Hi {firstName}! Ask me a question about your Brynsights™ data, I'm here to help. Here are some examples of questions:<br/>
                      <div className="grid grid-cols-3 gap-4 mt-4">
                        <div className="card rounded bg-slate-100 hover:bg-slate-300">
                          <div className="card-content">
                            <h5 className="mb-2 text-xl font-semibold text-center">
                              ILS Management™
                            </h5>
                            <ul className="list-disc ml-5 mt-2 text-base leading-tight">
                              <li>For each property, how many leads am I getting from each ILS?</li>
                              <li className="mt-2">What was the ROI on my spend on each ILS?</li>
                              <li className="mt-2">What Apartments.com packages are we listed on, and how many properties are on each?</li>
                              {/*
                              <li>For each property, what is my spend on each ILS?</li>
                              <li>For each property, how many leads am I getting for each ILS?</li>
                              <li>For Alexa Divide, which ILS gives me the best ROI?</li>
                              <li>What is my ILS spend for Alexa Divide?</li>
                              <li>What is my current occupancy at Alexa Divide and what is the 60 day exposure percentage?</li>
                              <li>What did I spend on each ILS each month this year?</li>
                              */}
                            </ul>
                          </div>
                        </div>
                        <div className="card rounded bg-slate-100 hover:bg-slate-300">
                          <div className="card-content">
                            <h5 className="mb-2 text-xl font-semibold text-center">
                              PMS Data
                            </h5>
                            {about !== "PMC Demo" &&
                              <p className="font-semibold text-bgold">Contact your client success manager to give Paul access to this data!</p>
                            }
                            <ul className="list-disc ml-5 mt-2 text-base leading-tight">
                              <li>What was my lead to move-in conversion for {propertiesNamesData?.properties[0]?.name}?</li>
                              <li className="mt-2">What was my lead to application conversion for {propertiesNamesData?.properties[0]?.name}?</li>
                              <li className="mt-2">What is my occupancy and future projected occupancy for my properties?</li>
                              {/*
                              <li>What is my average days vacant for Alexa Divide?</li>
                              <li>What is my average days from move out to ready for Alexa Divide?</li>
                              <li>What is my average days on market for Alexa Divide?</li>
                              <li>What is my average monthly income for Alexa Divide?</li>
                              <li>What has been my effective rent for Alexa Divide over the last 7, 30, 60, and 90 days?</li>
                              <li>What was my effective rent, but for new signs only for Alexa Divide?</li>
                              <li>What was my effective rent per square foot for Alexa Divide?</li>
                              <li>What is my average 'not ready' duration for Alexa Divide?</li>
                              */}
                            </ul>
                          </div>
                        </div>
                        <div className="card rounded bg-slate-100 hover:bg-slate-300">
                          <div className="card-content">
                            <h5 className="mb-2 text-xl font-semibold text-center">
                              SEO Data
                            </h5>
                            <ul className="list-disc ml-5 mt-2 text-base leading-tight">
                              <li className="mt-2">What are the top search keywords for {propertiesNamesData?.properties[0]?.name}?</li>
                              <li className="mt-2">What is the average CPC for keywords related to {propertiesNamesData?.properties[0]?.name}?</li>
                              <li className="mt-2">For {propertiesNamesData?.properties[0]?.name}, what are the top search results for studio apartments?</li>
                              <li className="mt-2">For {propertiesNamesData?.properties[0]?.name}, what are the top search results for 1 bedroom apartments?</li>
                              <li className="mt-2">What is the search volume and cost per click for 1-bedroom apartment searches for {propertiesNamesData?.properties[0]?.name}?</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                messages.map((message, index) => {

                  return (
                    <div className="flex flex-row items-center mb-2">
                      {message.sender === 'bot' && message.type !== 'table' &&
                        <div className="rounded-full bg-bgold h-[50px] w-[50px] flex items-center justify-center">
                          <span className="text-white font-semibold text-lg">Paul</span>
                        </div>
                      }
                      <div
                        key={index}
                        className={`p-4 text-lg rounded ${
                          message.sender === 'user' ? 'bg-blight ml-auto w-fit' : ''
                        } ${message.type !== 'table' ? 'max-w-[90%]' : 'w-full'}`}
                        >

                        {message.type === 'table' ? (
                          <DataFrame
                            df={message.text}
                            sql={message.sql}
                            onSaveChart={handleSaveChart}
                          />
                        ) : (
                          message.text
                        )}

                      </div>
                      {message.sender === 'user' &&
                        <img src={currentUserData?.currentUser?.photoUrl} alt="Avatar" className="h-[50px] w-[50px] rounded-full object-cover ml-2" />
                      }
                    </div>
                  );
                })
              )}
              {isLoading && (
                <div className="loading-indicator">Loading...</div>
              )}
            </div>
            <form onSubmit={handleSubmit} className="p-4 border-t">
              <div className="flex">
                <input
                  type="text"
                  value={inputMessage}
                  onChange={handleInputChange}
                  placeholder="Type a question..."
                  className="flex-grow border rounded-l-lg p-2 outline-none focus:ring-0"
                />
                <button
                  type="submit"
                  className="text-white rounded-r-lg p-2 hover:bg-blue-600"
                  style={{ backgroundColor: 'rgb(20, 49, 86)' }}
                >
                  Send
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="flex justify-center">
          {savedCharts.length > 0 ? (
              <div className="w-2/3 min-h-96 h-full flex flex-col">
                {savedCharts.map((chart) => {
                  return (
                    <div className="mt-6" key={chart.id}>
                      <SavedPaulChart chart={chart}  />
                    </div>
                  )}
                )}
              </div>
            ) : (
              <h2 className="text-lg font-medium italic max-w-[850px] mt-16 text-center">
                You don't have any saved charts yet. Chat with Paul and once he gives you a table or chart you like,
                you can save it here and it will automatically be updated with new data.
              </h2>
            )
          }
        </div>
      </BrynsonTabs>
    </div>
  );
}

export default Paul;
