import React, { useEffect } from 'react';

const trackComponentView = (componentName, additionalData = {}) => {
  if (window.Intercom) {
    window.Intercom('trackEvent', `${componentName}_viewed`, {
      location: window.location.pathname,
      timestamp: new Date().toISOString(),
      ...additionalData
    });
  }
};

export const useTrackComponentView = (componentName, additionalData = {}) => {
  useEffect(() => {
    trackComponentView(componentName, additionalData);
  }, []);
};
