import { ExportToCsv } from "export-to-csv"
import React, { useContext, useState, useCallback } from "react"
import styled from "styled-components"
import { GlobalContext } from "../context/GlobalContext"
import { propertyColumnsToDisplay } from "../queries/PropertiesColumns"
import BrynsonSquareButton from "./BrynsonSquareButton"
import BrynsonTable from "./BrynsonTable"
import Loading from "./Loading"
import PropertySidebar from "./PropertySidebar"
import PropertyUpload from "./PropertyUpload"

const TableWrapper = styled.div`
  .table th {
    white-space: nowrap;
    font-size: 13px;
  }
`

export default function PropertyShowDirectory() {
  const [showPropertyDetail, setShowPropertyDetail] = useState(false)
  const [propertyToDetail, setPropertyToDetail] = useState(null)
  const [showCreateProperty, setShowCreateProperty] = useState(false)
  const [uploadLoading, setUploadLoading] = useState(false)

  const { propertiesData: data, propertyReload: reexecuteQuery } = useContext(GlobalContext)

  const columns = propertyColumnsToDisplay

  const options = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useTextFile: false,
    useBom: true,
    filename: "Brynson Properties",
    headers: columns.map((item) => item.Header)
  }

  const fetchCreateProperties = useCallback((propertyInput) => {
    const propertiesValues = {}
    columns.forEach((item) => {
      if (item.accessor === "state") {
        propertiesValues[item.accessor] = propertyInput[item.accessor]?.abbreviation
      } else if (propertyInput[item.accessor]) {
        propertiesValues[item.accessor] =
          item.type === "number" ? parseInt(propertyInput[item.accessor]) : propertyInput[item.accessor]
      } else {
        propertiesValues[item.accessor_value || item.accessor] = ""
      }
    })
    return propertiesValues
  }, [columns])

  const exportToCSV = useCallback(() => {
    const csvExporter = new ExportToCsv(options)
    const exportable = data?.properties?.map((property) => fetchCreateProperties(property)) || []
    csvExporter.generateCsv(exportable)
  }, [data?.properties, fetchCreateProperties])

  const showHideProperty = useCallback((property) => {
    setShowPropertyDetail(true)
    setPropertyToDetail(property)
  }, [])

  const refreshScreen = useCallback(() => {
    setShowPropertyDetail(false)
    setPropertyToDetail(null)
    reexecuteQuery()
  }, [reexecuteQuery])

  const tableHeader = useCallback(() => (
    <div className="column is-8 is-offset-1 table-actions">
      <BrynsonSquareButton type="white" label="Export" onClick={exportToCSV} className="export" />
      <div className="property-upload">
        <PropertyUpload />
      </div>
      <BrynsonSquareButton
        type="primary"
        label="Add new property"
        onClick={() => setShowCreateProperty(true)}
      />
    </div>
  ), [exportToCSV])

  return (
    <div className="p-8">
      {data?.properties && !uploadLoading ? (
        <TableWrapper>
          <BrynsonTable
            data={data?.properties || []}
            columns={columns}
            countTitle={data?.properties?.length > 1 ? "total properties" : "property"}
            header={tableHeader}
            rowClick={showHideProperty}
          />
        </TableWrapper>
      ) : (
        <Loading />
      )}
      <PropertySidebar
        propertyToDetail={propertyToDetail}
        showPropertyDetail={showPropertyDetail}
        showCreateProperty={showCreateProperty}
        setShowPropertyDetail={setShowPropertyDetail}
        setShowCreateProperty={setShowCreateProperty}
        setPropertyToDetail={setPropertyToDetail}
        refreshScreen={refreshScreen}
      />
    </div>
  )
}