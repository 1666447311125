import clsx from "clsx"
import React, { useContext, useMemo, useState } from "react"
import Collapsible from "react-collapsible"
import styled from "styled-components"

import { GlobalContext } from "./context/GlobalContext"
import BrynsonSquareButton from "./shared/BrynsonSquareButton"
import DesktopH5 from "./shared/DesktopH5"
import DesktopTitle3 from "./shared/DesktopTitle3"
import DropdownCaret from "./shared/DropdownCaret"
import Modal from "./shared/Modal"
import Selectbox from "./shared/Selectbox"


const comingSoonItems = [
  { name: "Market Data", href: "/portal/market_data" },
  { name: "PMS/CRM Data", href: "/portal/pms_data" },
  { name: "Tell Paul", href: "/portal/coming_soon" },
]

const marketingItems = [
  { name: "SOCi", href: "/portal/soci" },
  { name: "Consumer Fusion", href: "/portal/consumer_fusion" }
]

const paidDigitalItems = [
  { name: "Lease Labs", href: "/portal/lease_lab" },
  { name: "Digible", href: "/portal/digible" }
]

const geoItems = [{ name: "Apartment Geofencing", href: "/portal/geofencing" }]


const settingsItems = [
  { name: "Personal", href: "/portal/settings/personal" },
  { name: "Billing", href: "/portal/coming_soon", additionalClass: "text-smalt cursor-not-allowed" },
  { name: "Communications", href: "/portal/coming_soon", additionalClass: "text-smalt cursor-not-allowed" }
]

const NavigationItem = ({ item, activeHref, indented, additionalClass }) => (
  <a
    href={item.href}
    className={clsx(
      additionalClass,
      "flex items-center py-4 pl-8 font-medium text-navy hover:text-bblue",
      { "bg-blight text-bblue": activeHref === item.href },
      { "ml-4 !py-2 text-sm": indented }
    )}>
    {item.name}
    {(item.name === 'Ask Paul' ||  item.name === 'ILS Intelligence') && (
      <span className="text-xxs ml-1 text text-bgold">BETA</span>
    )}
  </a>
);

const isFeatureRestricted = (featureName, userRestrictedFeaturesData) => {
  if (!userRestrictedFeaturesData?.userRestrictedFeatures?.restrictedFeatures) return false;

  return userRestrictedFeaturesData.userRestrictedFeatures.restrictedFeatures.includes(featureName);
};

const Wrapper = styled.div`
  height: 80px;
`

const LoadingSkeleton = () => (
  <div className="h-full w-[250px] pb-125 pt-[100px] portal-sidenav">
    <div className="p-3">
      <div className="h-10 bg-gray-200 rounded animate-pulse"></div>
    </div>
    <div className="space-y-4 mt-4">
      {[1, 2, 3, 4, 5].map((i) => (
        <div key={i} className="h-8 bg-gray-200 mx-8 rounded animate-pulse"></div>
      ))}
    </div>
  </div>
);



export default function SideNavigation({ activeHref }) {
  const { companiesData, currentUserData, updateCurrentUser, currentUserReload, propertyReload, userRestrictedFeaturesData } =
    useContext(GlobalContext)
  const [showCompaniesModal, setShowCompaniesModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [currentCompany, setCurrentCompany] = useState()
  const company = useMemo(() => currentUserData?.currentUser?.currentCompany, [currentUserData])
  const companies = useMemo(() => companiesData?.companies, [companiesData])
  const iframes = useMemo(() => currentUserData?.currentUser?.currentCompany?.iframeIntegrations, [currentUserData])


  const isLoading = !userRestrictedFeaturesData || !currentUserData || !companiesData;

  let ilsItems = useMemo(() => {
    const baseItems = [
      { name: "Property Directory", href: "/portal/property_directories" },
      { name: "Syndication", href: "/portal/listings" },
      { name: "DCAAs", href: "/portal/dcaas" },
      { name: "Quarterly Audits", href: "/portal/quarterly_audits" },
      { name: "ILS Performance Data", href: "/portal/conversions" },
      { name: "Photoshoots & Benefits", href: "/portal/benefits" },
      { name: "Source Tracking Details", href: "/portal/source_tracking" },
      { name: "Activity Log", href: "/portal/activity_log" },
      { name: "ILS Rates", href: "/portal/ils_rates" },
    ].filter(item => !isFeatureRestricted(item.name, userRestrictedFeaturesData));

    if (company?.about !== "PMC Demo" && !isFeatureRestricted("ILS Intelligence", userRestrictedFeaturesData)) {
      baseItems.push({ name: "ILS Intelligence", href: "/portal/ils_intelligence" });
    }

    return baseItems;
  }, [company, userRestrictedFeaturesData]);

  const navigationItems = useMemo(() => {
    const items = [
      { name: "Ask Paul", href: "/portal/paul" },
      { name: "Competitor Analysis", href: "/portal/comp_analysis" },
      { name: "Who's Searching What", href: "/portal/seo_audits" },
      { name: "Portfolio Intelligence Dashboard", href: "/portal/monday_report" },
      { name: "Integration Directory", href: "/portal/integration" },
    ].filter(item => {
      // Special handling for "Ask Paul" since it might be listed as just "Paul" in restrictions
      if (item.name === "Ask Paul") {
        return !isFeatureRestricted("Paul", userRestrictedFeaturesData);
      }
      return !isFeatureRestricted(item.name, userRestrictedFeaturesData);
    });

    if (company?.about === "Olympus Property") {
      items.push({ name: "Infrastructure Report", href: "/portal/incidents" });
      items.push({ name: "Jobs Summary", href: "/portal/jobs" });
    }

    return items;
  }, [company, userRestrictedFeaturesData]);

  const settingsOpened = activeHref.indexOf("settings") > -1
  const ilsOpened =
    activeHref.indexOf("portal/properties") > -1 ||
    activeHref.indexOf("portal/property_directories") > -1 ||
    activeHref.indexOf("portal/conversions") > -1 ||
    activeHref.indexOf("portal/listings") > -1 ||
    activeHref.indexOf("portal/benefits") > -1 ||
    activeHref.indexOf("portal/quarterly_audits") > -1 ||
    activeHref.indexOf("portal/dcaa") > -1 ||
    activeHref.indexOf("portal/activity_log") > -1 ||
    activeHref.indexOf("portal/source_tracking") > -1 ||
    activeHref.indexOf("portal/ils_intelligence") > -1 ||
    activeHref.indexOf("portal/ils_rates") > -1

  const marketingOpened =
      activeHref.indexOf("portal/soci") > -1 ||
      activeHref.indexOf("portal/consumer_fusion") > -1

  const geoOpened = activeHref.indexOf("geofencing") > -1

  const paidOpened =
    activeHref.indexOf("portal/lease_lab") > -1 ||
    activeHref.indexOf("portal/digible") > -1



  const iframeCategories = useMemo(() => {
    return (iframes || []).reduce((acc, iframe) => {
      if (!acc[iframe.category]) {
        acc[iframe.category] = [];
      }
      acc[iframe.category].push(iframe);
      return acc;
    }, {});
  }, [iframes]);

  const [openStates, setOpenStates] = useState({});

  const handleToggle = (category) => {
    setOpenStates(prev => ({
      ...prev,
      [category]: !prev[category]
    }));
  };

  if (isLoading) {
    return <LoadingSkeleton />;
  }

  return (
    <div className="b h-full w-[250px] pb-125 pt-[100px] portal-sidenav">
      <Wrapper>
        <div className="p-3">
          <Selectbox
            options={companies
              ?.sort((a, b) => (a.about > b.about ? 1 : -1))
              ?.map((i) => ({ value: i.id, label: i.about }))}
            defaultValue={{ value: company?.id, label: company?.about }}
            onChange={(value) => {
              const nextCompany = companies.find((item) => item.id === value)
              setCurrentCompany(nextCompany)
              setShowCompaniesModal(true)
            }}
          />
        </div>
      </Wrapper>
      <Collapsible
        open={ilsOpened}
        transitionTime={100}
        triggerClassName="flex items-center rounded py-4 pl-8 font-medium text-navy hover:text-bblue [&>*]:hover:border-bblue"
        triggerOpenedClassName="flex items-center rounded py-4 pl-8 font-medium text-bblue"
        trigger={
          <>
            <span>ILS Management™</span>
            <DropdownCaret expanded={false} />
          </>
        }
        triggerWhenOpen={
          <>
            <span>ILS Management™</span>
            <DropdownCaret expanded={true} />
          </>
        }>
        {ilsItems.map((item) => (
          <NavigationItem key={item.name} item={item} activeHref={activeHref} indented />
        ))}
      </Collapsible>
      {navigationItems.map((item) => (
        <NavigationItem key={item.name} item={item} activeHref={activeHref} />
      ))}


      {iframes?.length == 0 ? (
        <>
          {/* Geofencing services */}
          <Collapsible
            open={geoOpened}
            transitionTime={100}
            triggerClassName="flex items-center rounded py-4 pl-8 font-medium text-navy hover:text-bblue [&>*]:hover:border-bblue"
            triggerOpenedClassName="flex items-center rounded py-4 pl-8 font-medium text-bblue"
            trigger={
              <>
                <span>Geofencing</span>
                <DropdownCaret expanded={false} />
              </>
            }
            triggerWhenOpen={
              <>
                <span>Geofencing</span>
                <DropdownCaret expanded={true} />
              </>
            }>
            {geoItems.map((item) => (
              <NavigationItem key={item.name} item={item} activeHref={activeHref} indented />
            ))}
          </Collapsible>

          {/* Paid services */}
          <Collapsible
            open={paidOpened}
            transitionTime={100}
            triggerClassName="flex items-center rounded py-4 pl-8 font-medium text-navy hover:text-bblue [&>*]:hover:border-bblue"
            triggerOpenedClassName="flex items-center rounded py-4 pl-8 font-medium text-bblue"
            trigger={
              <>
                <span>Paid Digital Advertising</span>
                <DropdownCaret expanded={false} />
              </>
            }
            triggerWhenOpen={
              <>
                <span>Paid Digital Advertising</span>
                <DropdownCaret expanded={true} />
              </>
            }>
            {paidDigitalItems.map((item) => (
              <NavigationItem key={item.name} item={item} activeHref={activeHref} indented />
            ))}
          </Collapsible>

          {/* Marketing services */}
          <Collapsible
            open={marketingOpened}
            transitionTime={100}
            triggerClassName="flex items-center rounded py-4 pl-8 font-medium text-navy hover:text-bblue [&>*]:hover:border-bblue"
            triggerOpenedClassName="flex items-center rounded py-4 pl-8 font-medium text-bblue"
            trigger={
              <>
                <span>Social/Rep Mgmt</span>
                <DropdownCaret expanded={false} />
              </>
            }
            triggerWhenOpen={
              <>
                <span>Social/Rep Mgmt</span>
                <DropdownCaret expanded={true} />
              </>
            }>
            {marketingItems.map((item) => (
              <NavigationItem key={item.name} item={item} activeHref={activeHref} indented />
            ))}
          </Collapsible>
        </>
      ) : (
        <>
          {Object.entries(iframeCategories).map(([category, iframesInThatCategory]) => (
            <Collapsible
              key={category}
              open={openStates[category] || false}
              onChange={() => handleToggle(category)}
              transitionTime={100}
              triggerClassName="flex items-center rounded py-4 pl-8 font-medium text-navy hover:text-bblue [&>*]:hover:border-bblue"
              triggerOpenedClassName="flex items-center rounded py-4 pl-8 font-medium text-bblue"
              trigger={
                <>
                  <span>{category}</span>
                  <DropdownCaret expanded={openStates[category] || false} />
                </>
              }
              triggerWhenOpen={
                <>
                  <span>{category}</span>
                  <DropdownCaret expanded={true} />
                </>
              }
            >
              {iframesInThatCategory.map((item) => (
                <NavigationItem key={item.id} item={{ name: item.name, href: `/portal/integrated_view?itemId=${item.id}` }} activeHref={activeHref} indented />
              ))}
            </Collapsible>
          ))}
        </>
      )}

      <Collapsible
        open={settingsOpened}
        transitionTime={100}
        triggerClassName="flex items-center rounded py-4 pl-8 font-medium hover:text-bblue [&>*]:hover:border-bblue"
        triggerOpenedClassName="flex items-center rounded py-4 pl-8 font-medium"
        trigger={
          <>
            <span>Settings</span>
            <DropdownCaret expanded={false} />
          </>
        }
        triggerWhenOpen={
          <>
            <span>Settings</span>
            <DropdownCaret expanded={true} />
          </>
        }>
        {settingsItems.map((item) => (
          <NavigationItem
            key={item.name}
            item={item}
            activeHref={activeHref}
            indented
            additionalClass={item.additionalClass}
          />
        ))}
      </Collapsible>
      <div className="border-t border-bgold border-dashed !text-smalt cursor-not-allowed">
        <span className="flex items-center py-4 pl-8 font-medium !text-smalt italic cursor-not-allowed">
          Coming Soon:
        </span>
        {comingSoonItems.map((item) => (
          <NavigationItem
            key={item.name}
            item={item}
            activeHref={activeHref}
            additionalClass="text-smalt cursor-not-allowed"
          />
        ))}

        <NavigationItem
          item={{ name: "Brynsights™ Recommendations", href: "/portal/coming_soon" }}
          activeHref={activeHref}
          additionalClass="text-smalt cursor-not-allowed"
        />

      </div>

      <Modal
        open={showCompaniesModal}
        closeModal={() => {
          setShowCompaniesModal(false)
        }}
        size="small"
        header={<DesktopH5 content="Switch company" />}
        footer={
          <>
            <BrynsonSquareButton
              type="link"
              size="medium"
              onClick={() => {
                setShowCompaniesModal(false)
              }}
              label="Close"
            />
            <BrynsonSquareButton
              type="warning"
              size="medium"
              loading={loading}
              className={"flex items-center justify-center"}
              onClick={() => {
                setLoading(true);
                updateCurrentUser({ currentCompanyId: parseInt(currentCompany?.id) }).then(() => {
                  currentUserReload();
                  window.location.href = '/portal/property_directories';
                  setLoading(false);
                  setShowCompaniesModal(false);
                });
              }}
              label="Confirm"
            />
          </>
        }>
        <DesktopTitle3 content={`Go to dashboard for ${currentCompany?.about}?`} />
      </Modal>
    </div>
  )
}
