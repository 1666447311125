import React, { useState, useEffect } from 'react'

export default function BrynsonTabs(
  {
    tabs,
    children,
    className,
    onSelected,
    initialActiveTab = 0
  }
) {
  const [activeTab, setActiveTab] = useState(initialActiveTab)

  useEffect(() => {
    setActiveTab(initialActiveTab)
  }, [initialActiveTab])

  const handleClick = (index) => {
    setActiveTab(index)
    if (onSelected) {
      onSelected(index)
    }
  }

  return (
    <div className={className}>
      <div className='border-blight border-b mb-2'>
        {
          tabs.map((tab, index) => {
            return (
              <div key={index} className="inline-block ml-4">
                <button
                  className={`px-4 py-2 rounded-t-md ${index === activeTab ? "bg-blight text-navy font-bold" : "bg-gray-200 text-navy font-medium bg-transparent"}`}
                  onClick={handleClick.bind(this, index)}
                >
                  <div className="flex items-center">
                    {tab.icon &&
                      <div className={`mr-1 inline-block ${!tab.icon && '-mr-3'}`}>
                        <tab.icon />
                      </div>
                    }
                    <div className="inline-block">
                      {tab.label}
                    </div>
                  </div>
                </button>
              </div>
            )
          })
        }
      </div>
      {
        children && children[activeTab]
      }
      {
        tabs[activeTab].render && tabs[activeTab].render()
      }
    </div>
  )
}