import React, { useContext } from "react"
import PropertiesProvider from "../../context/PropertiesContext"
import { GlobalContext } from "../../context/GlobalContext"
import PropertyShowDirectory from "../../shared/PropertyShowDirectory"

export default function PropertyDirectory({ practice, practiceUser }) {
  const { currentUserData } = useContext(GlobalContext)


  return (
    <PropertiesProvider>
      <div className="p-8">
        <h1 className="font-semibold text-xl">Property Overview</h1>
        <div className="w-full">
          <PropertyShowDirectory practice={practice} practiceUser={practiceUser} />
        </div>
      </div>
    </PropertiesProvider>
  )
}