import React, { useContext, useState } from "react";
import { useQuery } from 'urql';
import { GlobalContext } from "../../context/GlobalContext";
import { CLIENT_ACTIVITIES_QUERY, CREATE_CLIENT_ACTIVITY_MUTATITION } from "../../queries/ClientActivityQueries"
import AGGridTable from '../../shared/AGGridTable'
import Loading from "../../shared/Loading"
import ActivityLogForm from "./ActivityLogForm"

export default function ActivityLog() {
  const { currentUserData } = useContext(GlobalContext);
	const showActions = currentUserData?.currentUser?.email?.endsWith('@brynson.com')

  const [{ data: currentData }] = useQuery({
    query: CLIENT_ACTIVITIES_QUERY,
		requestPolicy: "network-only",
    pause: false
  })

  const columns = [
		{
			accessor: "dateRequested",
			title: "Date Requested",
			type: "text",
		},
    {
			accessor: "requestedBy",
			title: "Requested By",
			type: "text",
		},
    {
			accessor: "propertyName",
			title: "Property Name",
			type: "text",
		},
    {
			accessor: "ils",
			title: "ILS",
			type: "text",
		},
    {
			accessor: "currentPackage",
			title: "Current Package",
			type: "text",
		},
    {
			accessor: "currentPrice",
			title: "Current Price",
			type: "currency",
		},
    {
			accessor: "newPackage",
			title: "New Package",
			type: "text",
		},
    {
			accessor: "newPrice",
			title: "New Price",
			type: "currency",
		},
    {
			accessor: "requestedEffectiveDate",
			title: "Requested Effective Date",
			type: "string",
		},
    {
			accessor: "requestedType",
			title: "Request Type",
			type: "string",
		},
    {
			accessor: "dateInitiatedByBrynson",
			title: "Date initiated by Brynson",
			type: "string",
		},
    {
			accessor: "ilsRepRequestSentToName",
			title: "ILS rep request sent to (Name)",
			type: "string",
		},
    {
			accessor: "confirmedReceiptByIlsDate",
			title: "Confirmed receipt by ILS - Date",
			type: "string",
		},
    {
			accessor: "confirmedReceiptByIlsDate",
			title: "Confirmed receipt by ILS - Date",
			type: "string",
		},
    {
			accessor: "ilsSignedAgreementCompletedDate",
			title: "ILS signed agreement (Completed Date)",
			type: "string",
		},
    {
			accessor: "liveDateOnOffIls",
			title: "Live Date On/Off ILS",
			type: "string",
		},
    {
			accessor: "notes",
			title: "Notes",
			type: "string",
		},
    {
			accessor: "auditComments",
			title: "Audit Comments",
			type: "string",
		},
	]

	const dateRequestedCell = (props) => {
    const { value, data } = props;
    return showActions ? (
      <ActivityLogForm
        item={data}
        OpenModalComponent={({ onClick }) => (
          <span
            className="font-medium text-bblue underline cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              onClick();
            }}
          >
            {value}
          </span>
        )}
      />
    ) : (
      <span className="font-medium">{value}</span>
    );
  };

	const customCellRenderers = {dateRequested: dateRequestedCell}

  return (
    <div className="p-8">
      <h1 className="font-semibold text-xl mb-4">Activity Log</h1>
			{showActions && (
        <div className="mb-4 space-x-4">
					<ActivityLogForm
						OpenModalComponent={({ onClick }) => (
							<a
								onClick={onClick}
								className="text-bblue underline cursor-pointer"
							>
								Add new activity
							</a>
						)}
        	/>
        </div>
      )}
      <div className="w-full">
        { currentData ? (
          <div>
            <AGGridTable
              data={currentData.clientActivities}
              headerColumns={columns}
              customCellRenderers={customCellRenderers}
              customHeaderRenderers={{}}
            />
          </div>
        ) : <Loading></Loading> }
      </div>
    </div>
  );
}
