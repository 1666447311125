import React, { useContext, useEffect, useMemo, useState } from "react"
import { useQuery } from "urql"
import TimeInput from "./TimeInput"
import { OfficeHoursContext } from "../context/OfficeHoursContext"
import BreadCrumbs from "./BreadCrumbs"
import Loading from "./Loading"
import BrynsonSquareButton from "./BrynsonSquareButton"
import { INFORMATION_QUERY } from "../queries/InformationsMutations"
import SlideInAlert from "./SlideInAlert"

export default function OfficeHourShow({ params }) {
  const [alertContent, setAlertContent] = useState("")
  const [showAlert, setShowAlert] = useState(true)
  const [saving, setSaving] = useState(false)
  const { updateOfficeHour: update, createOfficeHour: create } = useContext(OfficeHoursContext)
  const [{ data: currentData }] = useQuery({
    query: INFORMATION_QUERY,
    requestPolicy: "network-only",
    variables: {
      id: params ? JSON.parse(params)["id"] : null
    },
    pause: !params
  })

  const page = "office hour"
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thurday', 'Friday', 'Saturday']
  const [items, setItems] = useState(days.map(day => ({
    id: null,
    name: day,
    errors: [],
    open: {
      hour: '',
      meridiem: 'AM'
    },
    close: {
      hour: '',
      meridiem: 'PM'
    }
  })))

  const parseTime = (time, defaultMeridiem = 'AM') => {
    time = time.toLowerCase()
    let hour = ''
    let meridiem = defaultMeridiem

    if (!time || time.includes("By Appointment") || time.includes("Closed")) return { hour: hour, meridiem: meridiem }

    // e.g: 4:00pm
    let match = time.match(/(\d{1,2}:\d{2})(am|pm)/i);
    if (match) {
      hour = match[1];
      meridiem = match[2];
    } else {
      [hour, meridiem] = time.split(' ')
    }

    const formattedHour = !hour ? '' : hour.split(':')[0].padStart(2, '0') + ':' + hour.split(':')[1]
    return { hour: formattedHour, meridiem }
  }

  const [breadCrumbs, setBreadCrumbs] = useState()

  const isValid = (item) => {
    const errors = []
    const hourRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;
    if (item.open.hour != '' && !hourRegex.test(item.open.hour)) {
      errors.push('Invalid open hour')
    }

    if (item.close.hour != '' && !hourRegex.test(item.close.hour)) {
      errors.push('Invalid close hour')
    }

    addErrors(item, errors)
    return errors.length === 0
  }

  const save = async () => {
    let invalid = false
    items.forEach(item => {
      if (!isValid(item)) {
        invalid = true
      }
    });

    if (invalid) { return }
    
    let hasError = false;
    setSaving(true)
    for (const item of items) {
      if (isValid(item)) {
        const payload = {
          id: item.id,
          day: item.name,
          openTime: `${item.open.hour} ${item.open.meridiem}`,
          closeTime: `${item.close.hour} ${item.close.meridiem}`,
          informationId: parseInt(currentData.information.id)
        }

        try {
          if (item.id == null) {
            await create(payload);
          } else {
            await update(payload);
          }
        } catch (error) {
          hasError = true;
        }
      }
    }

    if (hasError) {
      setAlertContent("There was an error saving office hours.");
    } else {
      setAlertContent("Office hours successfully edited!");
    }

    setShowAlert(true)
    setSaving(false)
  }

  useEffect(() => {
    if (currentData?.information?.officeHours) {
      const updatedItems = items.map(item => {
        const officeHour = currentData.information.officeHours.find(oh => oh.day === item.name)
        if (officeHour) {
          return {
            ...item,
            id: officeHour.id,
            open: parseTime(officeHour.openTime),
            close: parseTime(officeHour.closeTime, 'PM'),
          }
        }
        return item
      })
      setItems(updatedItems)
    }
  }, [currentData])

  useMemo(() => {
    if (currentData) {
      const breadCrumbs = [
        { link: "/portal/dashboard", title: "dashboard"},
        { link: "/portal/listings", title: `Property ${currentData.information.property.name || ""}`},
        { link: `/portal/informations/${currentData.information.id}/edit`, title: `Information ${currentData.information.structureType || ""}`},
        { link: null, title: page }
      ]
      setBreadCrumbs(breadCrumbs)
    }
  }, [currentData])

  const breadCrumbHeader = () => (
    <div className="mb-6">
      <BreadCrumbs items={breadCrumbs} />
    </div>
  )

  const handleChange = (dayName, type, field, value) => {
    setItems(prevItems => 
      prevItems.map(item => 
        item.name === dayName
          ? { ...item, [type]: { ...item[type], [field]: value } }
          : item
      )
    )
  }

  const addErrors = (item, errors) => {
    setItems(prevItems => 
      prevItems.map(prevItem => 
        prevItem.name === item.name
          ? { ...prevItem, ...{errors: errors} }
          : prevItem
      )
    )
  }

  return (
    <div className="p-8">
      {currentData?.information?.officeHours ? (
        <>
          {breadCrumbHeader()}
          { items.map((item) => (
            <div key={item.name}>
              <div className="flex items-center">
                <label className="w-26">{ item.name }</label>
                <div className="flex items-center space-x-2">
                  <div>
                    <TimeInput
                      defaultValue={item.open.hour}
                      onChange={value => handleChange(item.name, 'open', 'hour', value)}
                    />
                    <select
                      value={item.open.meridiem}
                      onChange={(input) => handleChange(item.name, 'open', 'meridiem', input.target.value)}
                      className="border border-gray-300 rounded p-2 w-20 ml-2"
                    >
                      <option value="am">AM</option>
                      <option value="pm">PM</option>
                    </select>
                  </div>
                  <span>to</span>
                  <div>
                    <TimeInput
                      defaultValue={item.close.hour}
                      onChange={value => handleChange(item.name, 'close', 'hour', value)}
                    />
                    <select
                      value={item.close.meridiem}
                      onChange={(input) => handleChange(item.name, 'close', 'meridiem', input.target.value)}
                      className="border border-gray-300 rounded p-2 w-20 ml-2"
                    >
                      <option value="am">AM</option>
                      <option value="pm">PM</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="text-red-500 text-sm h-7">
                { item.errors.map((err, index) => (
                  <span key={`${item.name}-${err}`}>
                    {err}{index < item.errors.length - 1 ? ', ' : ''}
                  </span>
                ))}
              </div>
            </div>
          ))}
          <div className="flex justify-center items-center w-full mt-3">
            <div>
              <BrynsonSquareButton
                type="primary"
                testId="add-new-property-button"
                loading={saving}
                onClick={save}
                label={`Edit ${page}`}
              />
            </div>
          </div>
          <SlideInAlert visible={showAlert} setVisible={setShowAlert}>
            {alertContent}
          </SlideInAlert>
        </>
      ) : <Loading />}
    </div>
  )
}
