import React from "react"
import Select from "react-select"
import styled from "styled-components"

import { selectStyling } from "../../utils/utils"

const SelectboxWrapper = styled.div`
  input {
    border: 1px solid #c8d6df;
    background: white;
    padding: 14px 16px;
    width: 100%;
    margin: 0;
    outline: 0;
    box-shadow: none;
    border-radius: 0.25rem;
    height: auto;
    font-size: 16px;
  }

  input:hover,
  input:focus {
    box-shadow: 0 0 0 1px #0d9da4;
  }

  input[id*="react-select"]:hover,
  input[id*="react-select"]:focus {
    box-shadow: none;
  }

  input::placeholder {
    font-size: 16px;
    font-weight: 400;
  }
`
export default function Selectbox({ defaultValue = null, options, onChange, id = null, isDisabled = false, placeholder = null, isClearable = false}) {
  return (
    <SelectboxWrapper>
      <Select
        options={options}
        defaultValue={defaultValue}
        placeholder={placeholder}
        isDisabled={isDisabled}
        isClearable={isClearable}
        id={id}
        onChange={(e) => {
          onChange(e?.value)
        }}
        menuPortalTarget={document.body}
        styles={selectStyling()}
      />
    </SelectboxWrapper>
  )
}
