import React, { useState, useCallback, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './ag-grid-custom.css';

const AGGridTable = ({
  data,
  headerColumns,
  customCellRenderers,
  customHeaderRenderers,
  context,
  height = '700px',
  footerRowStyle,
  getRowStyle
}) => {
  const [gridApi, setGridApi] = useState(null);

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: true,
      resizable: true,
      floatingFilter: true,
      menuTabs: ['filterMenuTab', 'generalMenuTab'],
      wrapHeaderText: true,
      autoHeaderHeight: true,
    }),
    []
  );

  const onGridReady = useCallback((params) => {
    console.log('Grid is ready');
    setGridApi(params.api);
  }, []);

  const isColumnEmpty = (accessor, data) => {
    return !data.some((row) => {
      const value = accessor(row);
      return value !== null && value !== undefined && value !== '';
    });
  };

  const formatValue = (params, type) => {
    const value = params.value;
    if (value === null || value === undefined) return '';
    const isFooter = params.node.footer === true;
    switch (type) {
      case 'currency':
        const numericValue = parseFloat(value);
        if (numericValue === 0) {
          return ''; // Return blank for zero currency values
        } else {
          const formattedValue = numericValue.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
          if (isFooter) {
            const descriptor = params.colDef.footerDescriptor || '';
            return descriptor + formattedValue;
          } else {
            return formattedValue;
          }
        }
      case 'dateTime':
        try {
          const date = new Date(value);
          if (isNaN(date)) return value;
          return date.toLocaleString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: '2-digit',
            hour12: true
          }).replace(',', '').replace(' at ', ' at ');
        } catch (error) {
          console.error('Error formatting date:', error);
          return value;
        }
      case 'percentage':
        return `${parseFloat(value).toFixed(2)}%`;
      case 'number':
        return parseFloat(value).toLocaleString('en-US');
      default:
        return value;
    }
  };

  const getColumnDefs = () => {
    let colIndex = 0;
    return headerColumns
      .map((column) => {
        const columnMinWidth = column.minWidth || undefined;
        const columnMaxWidth = column.maxWidth || undefined; // Get maxWidth

        if (column.type === 'dynamic') {
          return column.keys.map((key) => {
            const children = column.columns
              .map((def) => {
                const accessor = (row) => {
                  try {
                    return def.accessor(row, key);
                  } catch (error) {
                    console.error(
                      `Error accessing data for column ${def.title}:`,
                      error
                    );
                    return null;
                  }
                };

                if (isColumnEmpty(accessor, data)) return null;

                const field = 'col_' + colIndex++;
                return {
                  headerName: def.title,
                  field: field,
                  valueGetter: (params) => {
                    if (!params.data) return null;
                    return accessor(params.data);
                  },
                  valueFormatter: (params) => {
                    const value = params.value;
                    if (value === null || value === undefined) return '';
                    switch (def.type) {
                      case 'currency':
                        return `$${parseFloat(value).toFixed(2)}`;
                      case 'percentage':
                        return `${parseFloat(value).toFixed(2)}%`;
                      case 'number':
                        return parseFloat(value);
                      default:
                        return value;
                    }
                  },
                  cellRenderer: customCellRenderers[def.accessor],
                  pinned: column.pinned,
                  aggFunc: def.aggFunc,
                };
              })
              .filter(Boolean);

            return {
              headerName: '',
              headerGroupComponent: 'customHeaderGroupComponent',
              headerGroupComponentParams: {
                title: column.title(key),
              },
              children: children,
              marryChildren: true,
              pinned: column.pinned,
            };
          });
        } else if (column.type === 'group') {
          const children = column.columns
            .map((subColumn) => {
              const accessor =
                typeof subColumn.accessor === 'function'
                  ? subColumn.accessor
                  : (row) => row[subColumn.accessor];

              if (isColumnEmpty(accessor, data)) return null;

              const field = 'col_' + colIndex++;
              return {
                headerName: subColumn.title,
                field: field,
                valueGetter: (params) => {
                  if (!params.data) return null;
                  return accessor(params.data);
                },
                valueFormatter: (params) => formatValue(params, subColumn.type),
                cellRenderer: customCellRenderers[subColumn.accessor],
                pinned: column.pinned,
                aggFunc: subColumn.aggFunc,
                minWidth: subColumn.minWidth || columnMinWidth,
                maxWidth: subColumn.maxWidth || columnMaxWidth, // Include maxWidth
                wrapHeaderText: subColumn.wrapHeaderText,
                footerDescriptor: subColumn.footerDescriptor,
              };
            })
            .filter(Boolean);

          return {
            headerName: '',
            headerGroupComponent: 'customHeaderGroupComponent',
            headerGroupComponentParams: {
              title: column.title,
            },
            children: children,
            marryChildren: true,
            pinned: column.pinned,
          };
        } else {
          // For regular columns
          const accessor =
            typeof column.accessor === 'function'
              ? column.accessor
              : (row) => {
                  const keys = column.accessor.split('.');
                  return keys.reduce(
                    (obj, key) => (obj && obj[key] !== undefined ? obj[key] : null),
                    row
                  );
                };

          if (isColumnEmpty(accessor, data)) return null;

          const field = 'col_' + colIndex++;
          const colDef = {
            headerName: column.title,
            field: field,
            valueGetter: (params) => {
              if (!params.data) return null;
              return accessor(params.data);
            },
            valueFormatter: (params) => formatValue(params, column.type),
            pinned:
              column.pinned ||
              (column.className && column.className.includes('sticky left-0') ? 'left' : null),
            aggFunc: column.aggFunc,
            minWidth: columnMinWidth,
            maxWidth: column.maxWidth, // Include maxWidth
            wrapHeaderText: column.wrapHeaderText,
            footerDescriptor: column.footerDescriptor,
          };

          if (customCellRenderers && customCellRenderers[column.accessor]) {
            colDef.cellRenderer = customCellRenderers[column.accessor];
            colDef.cellRendererParams = { context: context };
          }

          // Include cellStyle if provided
          if (column.cellStyle) {
            colDef.cellStyle = column.cellStyle;
          }

          return colDef;
        }
      })
      .flat()
      .filter(Boolean);
  };

  const columnDefs = useMemo(() => getColumnDefs(), [headerColumns, data]);

  const CustomHeaderGroupComponent = (params) => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          whiteSpace: 'normal',
          textAlign: 'center',
          wordWrap: 'break-word',
          lineHeight: 'normal',
          padding: '4px',
        }}
      >
        {params.title}
      </div>
    );
  };

  const components = useMemo(
    () => ({
      customHeaderGroupComponent: CustomHeaderGroupComponent,
      ...customCellRenderers,
      ...customHeaderRenderers,
    }),
    [customCellRenderers, customHeaderRenderers]
  );

  if (!data) {
    return <div>Loading...</div>;
  }

  const handleRowStyle = params => {

    if (params.node.footer && footerRowStyle) {
      return footerRowStyle;
    }

    if (params.data && getRowStyle) {
      return getRowStyle(params);
    }

    return null;
  };

  return (
    <div className="ag-theme-alpine" style={{ height: height, width: '100%' }}>
      <AgGridReact
        rowData={data}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        onGridReady={onGridReady}
        enableRangeSelection={true}
        enableCellTextSelection={true}
        rowSelection="multiple"
        suppressRowClickSelection={true}
        components={components}
        context={context}
        enableCharts={true}
        statusBar={{
          statusPanels: [
            { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
            { statusPanel: 'agTotalRowCountComponent', align: 'center' },
            { statusPanel: 'agFilteredRowCountComponent' },
            { statusPanel: 'agSelectedRowCountComponent' },
            { statusPanel: 'agAggregationComponent' },
          ],
        }}
        sideBar={{
          toolPanels: ['columns', 'filters'],
          defaultToolPanel: '',
        }}
        groupIncludeTotalFooter={true}
        suppressAggFuncInHeader={true}
        getRowStyle={handleRowStyle}
      />
    </div>
  );
};

export default AGGridTable;
